import {LOGGED_IN_TYPE, LOGGED_OUT_TYPE, SHOW_ERROR_MESSAGE, STARTUP_ERROR} from '../store/actionTypes';

export const DEFAULT_STARTUP_STATE = {
    showErrorMessage: false,
    connectionFailedAttempts: 0
};

export default function defaultStartup (state = DEFAULT_STARTUP_STATE, action) {
    if (action.type === LOGGED_IN_TYPE) {
        return Object.assign({}, state, {...action.data, connectionFailedAttempts: 0});
    } else if (action.type === LOGGED_OUT_TYPE) {
        // reset data about user
        return DEFAULT_STARTUP_STATE;
    } else if (action.type === SHOW_ERROR_MESSAGE) {
        return Object.assign({}, state, {showErrorMessage: action.data});
    } else if (action.type === STARTUP_ERROR) {
        return Object.assign({}, state, {connectionFailedAttempts: state.connectionFailedAttempts + 1});
    }

    else {
        return state;
    }
}

export function getUsersShowCommentOnApprove(state) {
    return getUsersData(state, {userSettings: {}}).userSettings.showCommentOnApprove;
}

export function getUsersCurrentLanguage(state) {
    return getUsersData(state, {}).localeString;
}

export function getUsersCurrentContextId(state) {
    return getUsersData(state, {}).currentContext;
}

export function getUsersId(state) {
    return getUsersData(state, {}).currentUserId;
}

export function getUsersData(state, defaultValue = null) {
    return state.userConfiguration || defaultValue;
}

export function getSupportedLanguages(state) {
    return getUsersData(state, {}).supportedLanguages;
}

export function getUsersCurrentBusinessFeatures(state) {
    return getUsersData(state, {}).currentBusinessFeatures;
}

export function getUsersLoggedInData(state) {
    return state.loggedInUser;
}

export function getAvailableContextsCount(state) {
    return getUsersData(state, {userRoles: {}}).userRoles.numberOfAvailableCompanyContexts
}

export function getUsersSettings(state) {
    return getUsersData(state, {userSettings: {}}).userSettings;
}

export function getUsersRoles(state) {
    return getUsersData(state, {}).userRoles;
}

export function getErrorMessageVisibility(state) {
    return state.showErrorMessage;
}

export function getNumberOfConnectionAttempts(state) {
    return state.connectionFailedAttempts;
}
