import {AttachmentsForRenderProps} from "types/attachment";
import React from "react";
import Menu from "components/taskdetails-remake/panels/attachments/attachmentTypes/Images/Menu.function";
import {useSelector} from "react-redux";
import {Store} from "redux";
import * as reduxSelectors from "components/store/application.reducers";
import {changeImageZoomPercentage} from "components/taskdetails-remake/taskDetails.action";

const Html = ({collection, url}: AttachmentsForRenderProps): React.ReactElement => {
    const zoom = useSelector((store: Store) => reduxSelectors.getImageZoomPercentage(store));

    const iframeStyle = {
        width: (zoom / 100) * 100 + "%",
        transform: `scale(${zoom / 100})`
    }

    return <div className={"attachment-image-container h-100"}>
        <Menu zoomPercentage={zoom} changeZoom={changeImageZoomPercentage}/>
        {collection.urlsOriginalJson && Object.keys(collection.urlsOriginalJson).length > 0 &&
            <div className="document-page-image page-iframe overflow-auto bg-natural-white py-2 rounded-8">
                <iframe id={"iframe_" + collection.id} src={url || ""} sandbox=""
                        style={iframeStyle}
                        allowFullScreen={true}
                        title={"iframe_for_html_" + collection.id}
                        height={1400}/>
            </div>
        }
    </div>
}

export default Html;