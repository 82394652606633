import * as Api from "utils/api/api";
import { draftCommentSavingStatusChange } from "components/taskdetails-remake/taskDetails.action";
import { COMMENT_SAVING_STATUS } from "utils/constants";
import { handleError } from "utils/errorHandle.function";

export function saveNewDraftComment(dispatch: any,comment: string, savedDraftComment: string, documentUid: string) {
    const trimmedComment = comment;
    if(!trimmedComment && trimmedComment!== savedDraftComment) {
        return Api.deleteDraftComment(documentUid)
            .then(() => {
                dispatch(draftCommentSavingStatusChange(COMMENT_SAVING_STATUS.DONE));
            })
            .catch((error: any) => {
                dispatch(draftCommentSavingStatusChange(COMMENT_SAVING_STATUS.ERROR));
                if (error.errorCode !== 330) {
                    handleError(error);
                }
            });
    } else if (trimmedComment  !== savedDraftComment){
        return Api.insertDraftComment(documentUid, comment)
            .then(() => {
                dispatch(draftCommentSavingStatusChange(COMMENT_SAVING_STATUS.DONE));

            })
            .catch((error: any) => {
                dispatch(draftCommentSavingStatusChange(COMMENT_SAVING_STATUS.ERROR));
                if (error.errorCode !== 330) {
                    handleError(error);
                }
            });
    }
}