import React, {Component} from 'react';
import {TableSearchRow} from './TableSearchRow.component';
import * as _ from 'lodash';
import {DOCUMENT_TYPE, HISTORY_FILTERS, LOADING_STATUS, TABLE_COLUMNS_TYPES} from "utils/constants";
import {formatAmountValue, formatDocumentType} from "utils/valueFormatter.function";
import * as sort from 'sortabular';
import * as resizable from 'reactabular-resizable';
import {v4 as uuid} from 'uuid';
import * as Table from 'reactabular-table';
import * as Sticky from 'reactabular-sticky';
import 'reactabular-resizable/style.css';
import './resizable_table.scss';
import {launchDarkly} from "utils/launchDarkly";
import TableFooter  from "components/resizableTable/TableFooter.component";
//custom sorting logic to switch only between asc and desc while sorting
const sortingOrder = {
    FIRST: 'asc',
    asc: 'desc',
    desc: 'asc'
};

let searchTimeout;
let stopScroll = false;
const TABLE_MARGINS = 70;
const MIN_COL_WIDTH = 70;
const ERROR = "ERROR";

export class ResizableTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            gridWidth: window.innerWidth - TABLE_MARGINS,
            colDragWidth: []
        };
        this.tableHeader = null;
        this.tableBody = null;

        this.sortHeader = this.sortHeader.bind(this);
        this.getBody = this.getBody.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.onBodyScroll = this.onBodyScroll.bind(this);
        this.onGridFilter = this.onGridFilter.bind(this);
        this.getFilterValue = this.getFilterValue.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.buildTableModel = this.buildTableModel.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.onClearAllFilters = this.onClearAllFilters.bind(this);
        this.computeColumnWidth = this.computeColumnWidth.bind(this);
        this.getAllRowsSelected = this.getAllRowsSelected.bind(this);
        this.isFiltersActive = this.isFiltersActive.bind(this);
        this.onReload = this.onReload.bind(this);

        this.resizableHelper = resizable.helper({
            globalId: uuid(),
            getId: ({property}) => property
        });

    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        this.setState({
            columns: this.resizableHelper.initialize(this.buildTableModel())
        });
        if (this.tableBody && this.props.scrollTop > 0)
            this.tableBody.scrollTop = this.props.scrollTop;
    }

    isFiltersActive() {
        return this.props.filters && Object.keys(this.props.filters).some(key => {
            return (this.props.filters[key] && Object.keys(this.props.filters[key]).length > 0);
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.defaultColumns !== this.props.defaultColumns)
            this.setState({
                colDragWidth: []
            });
        if (this.tableBody && this.props.scrollTop > 0)
            this.tableBody.scrollTop = this.props.scrollTop;
        if (this.tableHeader)
            this.tableHeader.scrollLeft = "0px";
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
        //this.resizableHelper.cleanup();
        delete this.resizableHelper;
        if (this.props.saveScrollPosition && this.tableBody) {
            this.props.saveScrollPosition(this.tableBody.scrollTop);
        }
    }

    computeColumnWidth(availableWidth, commonColsNum, column, index) {
        let computeSize;
        let foundIndex = _.findIndex(this.state.colDragWidth, {index: index});
        let currentWidth = (foundIndex !== -1) ? this.state.colDragWidth[foundIndex].width : column.width;
        if (!currentWidth) {
            computeSize = (availableWidth / commonColsNum > MIN_COL_WIDTH) ? availableWidth / commonColsNum : MIN_COL_WIDTH;
        } else {
            computeSize = currentWidth;
        }
        if (this.props.tasks.rows.length > 10) {
            if ((this.props.defaultColumns.length + 1) % 2 === 0) {
                return computeSize - 1.5;
            } else return computeSize - 1;
        } else {
            return computeSize - 0.5;
        }
    }

    buildTableModel() {
        const resizableFormatter = resizable.column({
            onDrag: (width, {column, columnIndex}) => {
                if (width > 130) {
                    let newWidth = _.cloneDeep(this.state.colDragWidth);
                    let index = _.findIndex(newWidth, {index: columnIndex});
                    if (index !== -1)
                        newWidth[index].width = width;
                    else
                        newWidth.push({
                            index: columnIndex,
                            width: width
                        });
                    this.setState({
                        colDragWidth: newWidth

                    });
                }
            }
        });

        const sortable = sort.sort({
            getSortingColumns: () => this.props.sortingLogic || [],
            onSort: (selectedColumn, event) => {
                if (this.props.sortingLogic) {
                    let sortBy = sort.byColumn({
                        sortingColumns: this.props.sortingLogic,
                        selectedColumn,
                        sortingOrder: sortingOrder
                    });
                    let newFilter = Object.assign({}, this.props.filters);
                    newFilter.sort = {
                        direction: sortBy[selectedColumn].direction,
                        index: this.props.defaultColumns[selectedColumn].searchValue
                    };
                    newFilter.sortBy = sortBy;

                    this.props.sortingColumnChanged(newFilter);
                    this.props.refreshGrid(newFilter);
                }
            }
        });

        const sortableHeader = this.sortHeader(sortable, () => this.props.sortingLogic, this.props.sortingLogic, this);

        let columnsMap = Object.assign([], this.props.defaultColumns);

        let emptyOnChange = (e) => {
            e.stopPropagation();
        };
        stopScroll = false;
        let cellFormatter = function (rowData, column) {
            if (column.type === TABLE_COLUMNS_TYPES.CHECKBOX) {
                return (<div style={{textAlign: "center", width: column.width}}
                             onClick={(e) => {
                                 e.stopPropagation();
                                 if (this.props.taskSelect)
                                     this.props.taskSelect(rowData.index)
                             }}>
                        <div className={"checkbox mx-0 pe-3 mt-0"}>
                            <input className="checkbox-input"
                                   onChange={emptyOnChange}
                                   type={column.type}
                                   id={column.name}
                                   checked={rowData.isSelected}
                            />
                            <label htmlFor={column.name}/>
                        </div>
                    </div>
                )
            } else if (column.type === TABLE_COLUMNS_TYPES.SELECT || column.type === TABLE_COLUMNS_TYPES.PROCESS_SELECT) {
                let docType = formatDocumentType(rowData.documentType, rowData.amount);
                return <span style={{width: column.width}}
                             className={"text-center " + docType}
                             title={this.props.translate("documentType." + DOCUMENT_TYPE.asString(rowData.documentType))}/>
            } else if (column.type === TABLE_COLUMNS_TYPES.TASK_STATUS) {
                let statusClass = rowData.taskOverviewStatus === ERROR ? "vismaicon vismaicon-sm vismaicon-filled vismaicon-error" : "task-" + rowData.taskOverviewStatus;
                return <div style={{width: column.width - 12}} className="text-center">
                    <span className={statusClass}
                          title={this.props.translate("taskOverview.status." + rowData.taskOverviewStatus)}/></div>
            } else if (column.type === TABLE_COLUMNS_TYPES.AMOUNT) {
                let amount = formatAmountValue(rowData[column.property]);
                return (<span
                    className={"cropped-text column-" + column.type + ((rowData[column.property] < 0) ? " text-danger" : "")}
                    style={{width: column.width - 12}}
                    title={amount}>{amount}</span>);
            } else if (column.type === "custom") {
                return rowData[column.property];
            } else {
                return (<span className={"cropped-text column-" + column.type} style={{width: column.width - 12}}
                              title={rowData[column.property]}>{rowData[column.property]}</span>);
            }

        }.bind(this);

        let availableWidth = this.props.tableWidth ? this.props.tableWidth - 10 : document.body.clientWidth - 92;
        let largeColsNum = columnsMap.filter(x => x.width).length;
        let commonColsNum = columnsMap.length - largeColsNum;

        columnsMap.forEach(col => {
            if (col.width)
                availableWidth -= col.width;
        });
        let columnsResult = columnsMap.map((column, index) => {

            let computeSize = this.computeColumnWidth(availableWidth, commonColsNum, column, index);
            let model = {
                    property: column.value,
                    type: column.type,
                    searchValue: column.searchValue,
                    selectedValue: column.selectedValue,
                    editable: true,
                    header: {
                        label: column.caption,
                        formatters: [
                            (v, extra) => resizableFormatter(sortableHeader(v, extra, this.props.sortingLogic), extra)
                        ],
                        props: {
                            style: {
                                width: computeSize,
                                textAlign: column.align ? column.align : 'left'
                            },
                            id: column.searchValue + "-" + index
                        }
                    },
                    cell: {
                        formatters: [
                            (value, {rowData, column, columnIndex}) => cellFormatter(rowData, column, columnIndex, value)
                        ],
                        props:
                            {
                                style: {
                                    textAlign: column.align ? column.align : 'left',
                                    width: computeSize,
                                    height:
                                        '30px'
                                },
                                headers: column.searchValue + "-" + index
                            }
                    }
                    ,
                    width: computeSize,
                    height:
                        40
                }
            ;

            return model;
        });

        columnsResult.push({
            property: "",
            type: TABLE_COLUMNS_TYPES.CLEAR_ALL_FILTERS,
            editable: false,
            header: {
                label: "",
                props: {
                    style: {
                        width: 24
                    },
                    id: "clear_all_search-" + columnsMap.length
                }
            },
            cell: {
                props: {
                    style: {
                        height: '30px',
                        padding: this.props.defaultColumns.length < 6 ? "13px" : (this.props.defaultColumns.length & 1 ? "18.1px" : "16px"),
                        overflow: "hidden"
                    },
                    headers: "clear_all_search-" + columnsMap.length
                }
            },
            width: 24,
            height: 40
        });

        return columnsResult;
    }

    getColumns() {
        let columns = this.resizableHelper.initialize(this.buildTableModel());
        let result = columns.map(column => {
            column.header.label = this.props.translate(column.header.label);
            column.selectedValue = this.props.filters.columns[column.searchValue] ? this.props.filters.columns[column.searchValue] : '';
            return column;
        });
        return result;
    }

    getBody(tableWidth) {
        if (this.props.loadingStatus === LOADING_STATUS.LOADING && (!this.props.asyncLoadingState || this.props.asyncLoadingState?.cursor === null || this.props.asyncLoadingState?.cursor === "")) {
            return (<span className="spinner spinner-default-blue loading"/>);
        }
        if (this.props.tasks.rows.length > 0) {
            return (
                <Sticky.Body rows={this.props.tasks.rows} rowKey="index"
                             className="history-body"
                             tabIndex={0}
                             style={{
                                 width: tableWidth,
                                 top: this.state.gridTop + 'px',
                                 paddingRight: '0',
                                 paddingLeft: '0',
                                 overflow: 'auto'
                             }}
                             ref={tableBody => {
                                 this.tableBody = tableBody && tableBody.getRef();
                             }}
                             tableHeader={this.tableHeader}
                             onScroll={this.onBodyScroll}
                             onRow={this.props.onRow ? this.props.onRow.bind(this) : undefined}
                />
            )
        }
        if (this.props.loadingStatus === LOADING_STATUS.DONE) {
            return (
                    <div
                        className="col-md-12 px-0 mt-12 d-block transparent-background text-center align-vertical-center">
                        {this.props.noDataString ?
                            this.props.noDataString :
                            (this.isFiltersActive() ?
                                    <div>
                                        <div className="visma-pictogram visma-pictogram-search-code"></div>
                                        <div className="title">{this.props.translate("grid.search.noResults")}</div>
                                        <div>{this.props.translate("grid.search.noMatchedResults")}</div>
                                        <div>{this.props.translate("grid.search.alterSearch")}</div>
                                    </div> :
                                    <div>
                                        <div className="visma-pictogram visma-pictogram-empty-folder"></div>
                                        <div className="title">{this.props.translate("grid.empty.noTasks")}</div>
                                        <div>{this.props.translate("grid.empty.readyForNewTask")}</div>
                                    </div>
                            )
                        }
                    </div>
            );
        }
    }

    getFilterValue(searchValue, existingFilter) {
        let newFilter = Object.assign({}, existingFilter);
        if (searchValue) {
            let key = Object.keys(searchValue)[0];
            newFilter[key] = (searchValue[key]);
        }
        return newFilter;
    }

    //get the missing tasks from backend
    onBodyScroll({target: {scrollHeight, scrollTop, offsetHeight}}) {
        let {rows, records, page} = this.props.tasks;
        //only get more tasks if the number of total records is larger then the current number of tasks
        //if (scrollHeight - scrollTop === clientHeight && (rows.length > 0 && rows.length < records)) {
        if (!stopScroll && !(this.props.loadingStatus === LOADING_STATUS.LOADING)) {
            if ((parseInt((scrollTop + offsetHeight + 1), 10) >= scrollHeight) &&
                ((rows.length > 0 && rows.length < records) || this.props.asyncLoadingState?.cursor != null)) {
                stopScroll = true;
                let newPage = page + 1;
                let callObject = {
                    page: newPage,
                    rows: 40,
                    filters: this.props.filters
                }
                this.props.loadMoreTasks(callObject);
            }
        }
    }

    onReload() {
        let callObject = {
            page: this.props.tasks.page + 1,
            rows: 40,
            filters: this.props.filters
        }
        this.props.loadMoreTasks(callObject);
    }

    //give user some time to finish typing before sending the request to backend
    onGridFilter(searchValue, columnProperty) {
        if (searchTimeout !== undefined)
            clearTimeout(searchTimeout);
        let columnsObject = {};
        let columns = Object.assign({}, this.props.filters.columns);
        if (searchValue[columnProperty] !== -1 && searchValue[columnProperty] !== "-1") {
            columns[columnProperty] = searchValue[columnProperty];
        } else {
            if (columns[columnProperty])
                delete columns[columnProperty];

        }
        columnsObject[HISTORY_FILTERS.COLUMNS] = columns;

        let newFilter = this.getFilterValue(columnsObject, this.props.filters);
        this.props.filterChanged(newFilter);
        searchTimeout = setTimeout(this.props.refreshGrid.bind(null, newFilter), this.props.debounceTimeout || 700);
    }

    onClearAllFilters() {
        if (searchTimeout !== undefined)
            clearTimeout(searchTimeout);
        let currentFilter = Object.assign({}, this.props.filters);
        currentFilter.columns = {};
        this.props.filterChanged(currentFilter);
        searchTimeout = setTimeout(this.props.refreshGrid.bind(null, currentFilter), this.props.debounceTimeout || 700);
    }

    selectAll() {
        let allTasksIndex = [];
        this.props.tasks.rows.forEach((task, index) => {
            allTasksIndex.push(index);
        });
        this.props.taskSelect(allTasksIndex);
    }

    updateDimensions() {
        this.setState({
            gridWidth: window.innerWidth - TABLE_MARGINS
        });
    }

    getAllRowsSelected() {
        if (!this.props.tasks.rows || this.props.tasks.rows.length === 0) return false;
        if (this.props.selectedTasks && this.props.tasks) {
            return this.props.selectedTasks.length === this.props.tasks.rows.length;
        }
        return false;
    }

    render() {
        let marginsWidth = 60;
        let tableWidth = this.props.tableWidth ? (this.props.tableWidth + 24) : (document.body.clientWidth - marginsWidth);

        let gridColumns = this.getColumns();
        let grid = this.getBody(tableWidth);
        let allRowsSelected = this.getAllRowsSelected();

        return (
            <div className="col-md-12 p-0">
                <div id="resizableGrid" className={this.props.className}>
                    <Table.Provider columns={gridColumns}
                                    className="table table-hover"

                                    style={{
                                        width: 'auto',
                                        maxHeight: "100%",
                                        overflow: 'hidden'
                                    }}>
                        <Sticky.Header
                            style={{
                                width: tableWidth,
                                overflow: 'hidden'
                            }}
                            ref={tableHeader => {
                                this.tableHeader = tableHeader && tableHeader.getRef();
                            }}
                            tableBody={this.tableBody}>
                            {!this.props.hideSearchRow &&
                                <TableSearchRow columns={gridColumns}
                                                selectors={this.props.selectors}
                                                filters={this.props.filters}
                                                onSearch={this.onGridFilter}
                                                selectAll={this.selectAll}
                                                allRowsSelected={allRowsSelected}
                                                companyId={this.state.companyId}
                                                onClearAllFilters={this.onClearAllFilters}
                                                translate={this.props.translate}/>
                            }

                        </Sticky.Header>

                        {grid}

                    </Table.Provider>

                    <TableFooter records = {this.props.tasks.records}
                                 loadingStatus = {this.props.loadingStatus}
                                 loadingError = {this.props.loadingError}
                                 asyncLoadingState = {this.props.asyncLoadingState}
                                 showRowData = {!this.props.hideRowsNumber && this.props.tasks.records > 0}
                                 onReload = {this.onReload}/>
                </div>
            </div>
        )

    }

    sortHeader(sortable) {
        return (value, {column, columnIndex}, sortLogic) => {
            let headerStyle = {float: 'right'};
            if (sortLogic) {
                headerStyle.cursor = "pointer";
            }
            return (
                <div style={{display: 'inline'}} className="col-md-12 px-0">
                    {React.createElement(
                        'span',
                        sortable(
                            value,
                            {columnIndex},
                            {style: headerStyle, className: "value col-md-12 px-0"}
                        ),
                        value
                    )}
                </div>
            );
        };
    }
}

export default ResizableTable;


