import React, {Component} from 'react';
import * as api from "../../../utils/api/api";
import {AdvancedSearch} from "../../myhistory/HistoryAdvancedSearch.component";


export class SimulatorCostUnits extends Component {

    constructor(props) {
        super(props);

        this.inputRef = [];
    }

    onChange(value, index){
        this.props.onChange(
            this.props.costUnitTypes.map((item, itemIndex) => {
                if (index === itemIndex) {
                    return value;
                }
                return this.props.selected[itemIndex];
            })
        )
    }

    render() {
        return (
            <div className="col-md-6">
                <h4>{this.props.translate("workflowDetails.simulator.costUnits")}</h4>
                { this.props.costUnitTypes.map((costUnit, index) =>
                    <div className="form-group row cost-unit-wrapper" key={index}>
                        <AdvancedSearch ref={(input) => {this.inputRef[index] = input ? input.inputElement
                            : null}}
                                        bsClass="col-md-12"
                                        name={`${costUnit.name} (${costUnit.dimension})`}
                                        placeholder={''}
                                        namePosition={'top'}
                                        type={api.lookupCostCenters}
                                        usedInIndexOnly={false}
                                        selectedValue={this.props.selected[index]}
                                        dimension={costUnit.dimension}
                                        hasAny={false}
                                        hasUnassigned={false}
                                        onSearch={() => {}}
                                        onChange={(value) => {this.onChange(value, index)}}
                                        translate={this.props.translate}/>
                    </div>
                )}
            </div>
        );
    }
}
