import React from 'react';
import NumberFormat from 'react-number-format';
import {getLocaleSeparator, getLocaleThousandSeparator} from 'utils/i18n/wnumb'
import _ from 'lodash';
import {FormGroup, Form} from 'react-bootstrap';
import {EDITITNG_TYPE, KEYS} from 'utils/constants';
import Confirm from '../popup/ConfirmActionPopup.component';
import SearchableCombobox from './SearchableCombobox.component';
import * as Api from 'utils/api/api.js';
import moment from 'moment';
import DatePicker from "./FlatpickrWithBlur.component";
import {FLATPICKR_DATE_FORMATS, getDateFormatFromLanguage} from "utils/dateFormatter";


const AccountingGenericInput = ({props} = {}) => {

    class Input extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                currentValue: _.clone(props.value),
                revertValue: _.clone(props.value),
                shouldRevert: false,
                showError: false
            };
            this.onBlur = this.onBlur.bind(this);
            this.stopEvent = this.stopEvent.bind(this);
            this.pressed = this.pressed.bind(this);
            this.move = this.move.bind(this);
            this.movePrevious = this.movePrevious.bind(this);
            this.handleKeyDown = this.handleKeyDown.bind(this);
            this.revert = this.revert.bind(this);
            this.saveCell = this.saveCell.bind(this);
            this.revertCell = this.revertCell.bind(this);
            this.getInput = this.getInput.bind(this);
            this.checkIfCanSave = this.checkIfCanSave.bind(this);
            this.saveAndMove = this.saveAndMove.bind(this);
            this.dateValueFormatter = getDateFormatFromLanguage(this.props.extraParameters.language);
        }

        componentDidMount() {
            setTimeout(() => {
                if (this.genericInput) {
                    this.genericInput.focus();
                    this.genericInput.select();
                }
            }, 10);
        }

        handleKeyDown(event) {
            event.stopPropagation();

            switch (event.keyCode) {
                case KEYS.TAB: {
                    if (event.shiftKey)
                        this.movePrevious(event);
                    else
                        this.move(event);
                    break;
                }
                case KEYS.ENTER: {
                    event.preventDefault();
                    event.stopPropagation();
                    this.pressed();
                    break;
                }
                case KEYS.ESC: {
                    this.revert(event);
                    break;
                }
                default: {
                    break;
                }
            }
        }

        // @keydown('esc')
        revert(event) {
            this.stopEvent(event);
            this.setState({shouldRevert: true}, () => {
                this.props.onValue({val: this.state.revertValue, hasToMoveNext: false})
            })
        }

        checkIfCanSave(value) {
            if (value === null && this.props.extraParameters.type !== EDITITNG_TYPE.NUMERIC) {
                return false;
            }

            if (this.props.extraParameters.rowData.action && !this.props.extraParameters.rowData.actionsEnabled) {
                //not my line and something has changed
                if (value !== this.state.revertValue) {
                    this.setState({
                        showError: true
                    });

                    this.props.extraParameters.lineWarning(true);
                    return false;
                }
            }
            return true;
        }

        saveAndMove(value, next, event) {
            if (this.genericInput)
                this.genericInput.removeEventListener("blur", this.onBlur);
            let response = {
                val: value
            };
            if (next)
                response.hasToMoveNext = true;
            else response.hasToMovePrevious = true;

            if (this.checkIfCanSave(value)) {
                this.props.onValue(response);
            }
        }

        // @keydown('enter')
        pressed() {
            if (this.checkIfCanSave(this.state.currentValue)) {
                this.props.onValue({val: this.state.currentValue, switchToReadOnly: true, hasToMoveNext: false});
            }
        }

        //@keydown(9)
        move(event) {
            event.preventDefault();
            if (this.genericInput)
                this.genericInput.removeEventListener("blur", this.onBlur);
            this.props.onValue({val: this.state.currentValue, hasToMoveNext: true});
        }

        movePrevious(event) {
            event.preventDefault();
            //let trimNumber = this.genericInput.value.replace(/ /g, "");
            if (this.genericInput)
                this.genericInput.removeEventListener("blur", this.onBlur);
            this.props.onValue({val: this.state.currentValue, hasToMovePrevious: true});
        }

        onBlur(inputValue) {
            const parsedRevertValue = this.state.revertValue === null ? "" : this.state.revertValue;
            if (inputValue === parsedRevertValue) {
                this.props.onValue({val: null, switchToReadOnly: true});
                return;
            }

            let value = _.clone(this.state.currentValue);
            if (this.props.extraParameters.type === EDITITNG_TYPE.DATE && value) {
                value = moment(this.state.currentValue).format(this.dateValueFormatter);
            }

            if (!this.state.shouldRevert && this.checkIfCanSave(value) && !this.state.showError) {
                // let shouldCloseEditing = event.currentTarget ? event.currentTarget.nodeName === "TBODY" : true;
                this.props.onValue({val: value});
            }
        }

        stopEvent(event) {
            event.preventDefault();
            event.stopPropagation();
        }

        saveCell(event) {
            if (event) {
                this.stopEvent(event);
            }
            let currentValue = _.clone(this.state.currentValue);
            if (this.props.extraParameters.type === EDITITNG_TYPE.DATE && this.state.currentValue) {
                currentValue = moment(this.state.currentValue).format(this.dateValueFormatter);
            }

            this.props.onValue({val: currentValue, hasToMoveNext: false});
            this.props.extraParameters.lineWarning(false);
        }

        revertCell(event) {
            if (event) {
                this.stopEvent(event);
            }
            this.props.onValue({val: null, switchToReadOnly: true});
            this.props.extraParameters.lineWarning(false);
            this.setState({showError: false});
        }

        getInput() {
            let {value, extraParameters} = this.props;
            let valueFormatter = getLocaleSeparator(extraParameters.language ? extraParameters.language : 'en');
            let thousandSeparator = getLocaleThousandSeparator(extraParameters.language ? extraParameters.language : 'en');

            switch (extraParameters.type) {
                case EDITITNG_TYPE.NUMERIC:
                    return (
                        <div className="generic-input"
                             cellindex={extraParameters.tabIndex}>
                            <NumericInput value={this.state.currentValue}
                                          tabIndex={extraParameters.tabIndex}
                                          decimalSeparator={valueFormatter}
                                          onChange={(value) => {
                                              this.setState({currentValue: value});
                                          }}
                                          thousandSeparator={thousandSeparator}
                                          onKeyDown={this.handleKeyDown}
                                          onBlur={(value) => {
                                              this.setState({currentValue: value}, () => {
                                                  this.onBlur(value)
                                              });
                                          }}
                                          dataType={extraParameters.dataType}
                            />
                        </div>
                    );

                case EDITITNG_TYPE.DATE:
                    const currentValue = moment(this.state.currentValue, FLATPICKR_DATE_FORMATS[extraParameters.language].long).toDate();
                    return (
                        <div className="generic-input"
                             cellindex={extraParameters.tabIndex}>
                            <DatePicker
                                options={{
                                    allowInput: true,
                                    dateFormat: FLATPICKR_DATE_FORMATS[extraParameters.language].short
                                }}

                                value={currentValue}
                                onChange={([value]) => {
                                    this.setState({
                                        currentValue: value
                                    }, () => this.saveCell());
                                }}
                                handleBlur={(value) => {
                                    this.setState({
                                        currentValue: moment(value, FLATPICKR_DATE_FORMATS[extraParameters.language].long).toDate()
                                    })
                                }}
                            />
                        </div>
                    );
                case EDITITNG_TYPE.COST_CENTER:
                case EDITITNG_TYPE.ACCOUNT:
                    return (
                        <div className="generic-input"
                             cellindex={extraParameters.tabIndex}>
                            <SelectInput value={this.state.currentValue || ""}
                                         tabIndex={extraParameters.tabIndex}
                                         extraParameters={extraParameters}
                                         onChange={(values, e) => {
                                             e.stopPropagation();
                                             e.preventDefault();
                                             let self = this;
                                             self.setState({currentValue: values.value}, () => {
                                                 self.pressed();
                                             });
                                         }}
                                         onEmpty={(e) => {
                                             this.setState({currentValue: ""});
                                         }}
                                         saveAndMove={this.saveAndMove}
                                         onKeyDown={this.handleKeyDown}
                                         onBlur={(e) => this.onBlur(e.target.value)}
                            />
                        </div>
                    );
                default:
                    return (
                        <div className="generic-input"
                             cellindex={extraParameters.tabIndex}>
                            <GenericInput defaultValue={value}
                                          type={extraParameters.type}
                                          tabIndex={extraParameters.tabIndex}
                                          decimalSeparator={valueFormatter}
                                          onChange={(e) => {
                                              e.stopPropagation();
                                              e.preventDefault();
                                              this.setState({currentValue: e.target.value});
                                          }}
                                          onKeyDown={this.handleKeyDown}
                                          onBlur={this.onBlur}
                            />
                        </div>
                    )
            }
        }


        render() {
            let input = this.getInput();
            let {extraParameters} = this.props;

            return (
                <div>
                    {this.state.showError &&
                        <Confirm translate={extraParameters.translate}
                                 message={extraParameters.translate("accoutingGrid.notApproverLineWarning")}
                                 handleAction={this.saveCell} closeCallback={this.revertCell}/>
                    }
                    {input}
                </div>);
        }
    }

    return Input;
};

export default AccountingGenericInput;

class NumericInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inputNumber: props.value ? props.value : 0
        };
        this.numericInput = null;

    }

    componentDidMount() {
        setTimeout(() => {
            if (this.numericInput) {
                this.numericInput.focus();
                this.numericInput.select();
            }
        }, 100);
    }

    render() {
        const decimalScale = this.props.dataType === EDITITNG_TYPE.INTEGER ? 0 : 2;
        return (
            <div>
                <Form className="col-md-12 px-0">
                    <FormGroup className="search-group col-md-12 px-0">
                        <NumberFormat value={this.state.inputNumber}
                                      getInputRef={(input) => {
                                          this.numericInput = input;
                                      }}
                                      tabIndex={this.props.tabIndex}
                                      thousandSeparator={this.props.thousandSeparator}
                                      decimalSeparator={this.props.decimalSeparator}
                                      allowNegative={true}
                                      fixedDecimalScale={true}
                                      decimalScale={decimalScale}
                                      className="accounting-numeric-input cropped-text form-control"
                                      onValueChange={(val) => {
                                          this.setState({inputNumber: val.formattedValue});
                                          this.props.onChange(val.floatValue)
                                      }}
                                      onKeyDown={this.props.onKeyDown}
                                      onBlur={(e) => {
                                          const convertedValue = parseFloat(e.target.value);
                                          const numericValue = (!isNaN(convertedValue) && isFinite(convertedValue)) ? e.target.value.split(' ').join('') : "";
                                          this.props.onBlur(numericValue)
                                      }}
                                      onClick={(e) => {
                                          e.stopPropagation()
                                      }}
                        />
                    </FormGroup>
                </Form>

            </div>
        )
    }
}

class GenericInput extends React.Component {

    constructor(props) {
        super(props);
        this.genericInput = null;
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.genericInput) {
                this.genericInput.focus();
                this.genericInput.select();
            }
        }, 10);
    }

    render() {
        return (
            <div>
                <Form className="col-md-12 px-0">
                    <FormGroup className="search-group col-md-11 px-0">
                        <input type={this.props.type} tabIndex={this.props.tabIndex}
                               {...this.props.type === "text" && {maxLength: 255}}
                               ref={(input) => {
                                   this.genericInput = input;
                               }}
                               className="accounting-input cropped-text form-control"
                               step={this.props.step}
                               onChange={this.props.onChange}
                               onKeyDown={this.props.onKeyDown}
                               onBlur={(e) => this.props.onBlur(e.target.value)}
                               defaultValue={this.props.defaultValue}
                               onClick={(e) => {
                                   e.stopPropagation()
                               }}
                        />
                    </FormGroup>
                </Form>
            </div>
        )
    }
}

class SelectInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            revertValue: _.clone(props.value)
        }
    }

    render() {
        let {value, extraParameters} = this.props;
        let callFunctionAll, callFunction;

        callFunctionAll = extraParameters.type === EDITITNG_TYPE.COST_CENTER ? Api._lookupAllCostCenters.bind(null, [extraParameters.key, extraParameters.column.index]) : Api._lookupAllAccounts.bind(null, extraParameters.key);
        callFunction = extraParameters.type === EDITITNG_TYPE.COST_CENTER ? Api._findCostCenter.bind(null, [extraParameters.key, extraParameters.column.index]) : Api._findAccount.bind(null, extraParameters.key);
        return (
            <SearchableCombobox translate={extraParameters.translate}
                                handleKeyDown={this.props.onKeyDown}
                                loadAllFunc={callFunctionAll}
                                apiMethod={callFunction}
                                saveAndMove={this.props.saveAndMove}
                                onChangeSelected={this.props.onChange}
                                onEmpty={this.props.onEmpty}
                                onBlur={this.props.onBlur}
                                defaultValue={value}/>
        )
    }

}
