import React, {Component, Fragment} from 'react';
import {formatAmountValue} from 'utils/valueFormatter.function';
import BatchButtons from '../input/SplitSignButton.component';
import {BATCH_ACTIONS} from 'utils/constants';
import * as Api from 'utils/api/api';
import * as _ from "lodash";
import {
    approveMultipleTasks,
    approveMultipleTasksWithDraftComment,
} from "../mytasks/action/approveMultipleTasks.action";
import {handleError} from 'utils/errorHandle.function';

import {connect} from 'react-redux';
import * as reduxSelectors from '../store/application.reducers';
import {
    unbundleBatch,
    confirmBatch,
    hideAutopayiFrame
} from '../mytasks/myTasks.action';
import { launchDarkly } from "utils/launchDarkly";
import { getActionParameters } from "../store/application.reducers";

let ALLOW_RECEIVE_MESSAGE = true;

export class Batch extends Component {

    constructor() {
        super();
        this.state = {
            showPopUp: false
        };
        this.displayPopUp = this.displayPopUp.bind(this);
        this.receiveMessage = this.receiveMessage.bind(this);
        this.useDraftComments = launchDarkly.userClient?.variation("fe-draft-comments", false);

    }

    componentDidMount() {
        window.addEventListener('message', this.receiveMessage, true);
    }


    receiveMessage(e) {
        if (ALLOW_RECEIVE_MESSAGE && e.data && e.data.data === "fromPreApproval" && this.props.showAutopayiFrame) {
            ALLOW_RECEIVE_MESSAGE = false;
            window.removeEventListener("message", this.receiveMessage);
            this.props.hideAutopayiFrame();

            let secret = e.data.secret;
            let batchId = e.data.batchId;
            let batchAction = e.data.batchAction;

            Api.getActiveBatches().then(response => {
                if (batchAction === BATCH_ACTIONS.CANCEL) {
                    this.props.unbundleBatch(batchId, response.version);
                    ALLOW_RECEIVE_MESSAGE = true;
                }
                if (batchAction === BATCH_ACTIONS.CONFIRM && secret) {
                    if (response && response.documents) {
                        let taskIds = [];
                        response.documents.forEach(doc => {
                            let found = _.find(this.props.tasksWithPayment, (task) => {
                                return task.id === doc.taskId
                            });
                            if (found)
                                taskIds.push(found.key);
                        });
                        this.props.confirmBatch(batchId, response.version, secret).then(res => {
                            if(this.useDraftComments) {
                                const actionParams = this.props.actionParameters;
                                this.props.approveMultipleTasksWithDraftComment(actionParams.tasksData, undefined, undefined, undefined, true, this.props.navigate);
                            } else {
                                 this.props.approveMultipleTasks(taskIds, undefined, undefined, undefined, true,this.props.navigate);
                            }
                            ALLOW_RECEIVE_MESSAGE = true;
                        })
                    }
                } else
                    ALLOW_RECEIVE_MESSAGE = true;
            }, error => {
                handleError(error);
            })
        }
    }

    displayPopUp() {
        this.setState({
            showPopUp: true
        });
    }


    render() {
        let numTasks = this.props.batch && this.props.batch.documents ? this.props.batch.documents.length : '';

        let currencyTotal = 0;
        let currency = "";
        if (numTasks !== '') {
            this.props.batch.documents.forEach(doc => {
                if (currency === "") {
                    currency = doc.details.currency;
                }
                if (doc.details.currency === currency) {
                    currencyTotal += doc.details.maxApprovedAmount;
                } else {
                    currencyTotal = 0;
                    return;
                }

            });
        }

        let title = currencyTotal > 0 ? this.props.translate("myTasks.signBundleCurrency", numTasks, formatAmountValue(currencyTotal) + " " + currency) :
            this.props.translate("myTasks.signBundle", numTasks);

        return (
            <Fragment>
                <div className="alert alert-md row mx-0">
                    <span className="col-md-1 document-type-icon document-type-INVOICE"/>


                    <div className="col-md-5 px-0">{title}</div>
                    <div className="col-md-6 text-end">
                        <BatchButtons batch={this.props.batch}
                                      okCallback={this.displayPopUp}
                                      translate={this.props.translate}/>
                    </div>
                </div>

                {this.state.showPopUp && this.props.showAutopayiFrame &&
                    <div className="pop-up">
                        <div className="pop-up-modal">
                            <div className="panel panel-default">
                                {this.props.autopayURL &&
                                    <iframe title={"Autopay iFrame"} width="700" height="700"
                                            src={this.props.autopayURL}/>
                                }
                            </div>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
}

const
    mapStateToProps = function (store) {
        return {
            autopayURL: reduxSelectors.getAutopayURL(store),
            showAutopayiFrame: reduxSelectors.getShowAutopayiFrame(store),
            tasksWithPayment: reduxSelectors.getTasksWithPayment(store),
            actionParameters: getActionParameters(store),
        };
    };

const
    connected = connect(mapStateToProps, {
        unbundleBatch,
        confirmBatch,
        approveMultipleTasks,
        hideAutopayiFrame,
        approveMultipleTasksWithDraftComment,
    })(Batch);
export default connected;

