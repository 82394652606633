import React from 'react';
import translate from "../../../translations/translations.wrapper.jsx";
import WorkflowDetailsWizardStep
    from "components/taskdetails-remake/panels/workflowdetails/WorkflowDetailsWizardStep.component";

const WorkflowDetailsWizard = (props) => {
    const renderSteps = () => {
        return props.data.process.steps.filter((step) => {
            return props.hideSkippedSteps ? step.status !== "SKIPPED" : true
        }).map((step) => {
            return (
                <WorkflowDetailsWizardStep key={step.stepNumber}
                                           step={step}
                                           data={props.data}
                                           displaySourceApplication={props.data.displaySourceApplication}>
                    {step.name}
                </WorkflowDetailsWizardStep>
            )
        })
    }

    return (
        <div className="workflow-wizard">
            <WorkflowDetailsWizardStep approvalInitiated={true}
                                       key={"-1"}
                                       data={props.data}>
                {props.translate("taskDetail.approvalInitiated")}
            </WorkflowDetailsWizardStep>
            {renderSteps()}

            {props.data.process?.steps?.length === 0 && props.data.status === "CANCELED" &&
                <WorkflowDetailsWizardStep key={"no-workflow-canceled"}
                                           step={{
                                               errorExplanations: [props.translate("taskDetail.noWorkflowDocument")],
                                               status: "CANCELED",
                                               lastAction: props.data.statusChanged
                                           }}>
                    {props.translate("workflowProcessStatus.CANCELLED")}
                </WorkflowDetailsWizardStep>
            }
        </div>
    );

}

const withTranslations = translate(WorkflowDetailsWizard);
export default withTranslations;
