import { useEffect } from "react";
import {TASK_ACTIONS, DETAILS_VIEW_TYPE} from 'utils/constants';
import { useDispatch, useSelector } from "react-redux";
import {taskAction} from '../mytasks/action/taskAction.action';
import {TaskDetailActions} from '../mytasks/AvailableActions.class';
import {
    removeSelectedDocument,
    handleAddTasksComments,
    getTaskCommentsData, handleAddTasksDraftComments, getTaskCommentsByUid, getTaskDetailsByUID,
} from "./taskDetails.action";
import {formatTaskDetailsTopBanner} from 'utils/valueFormatter.function';
import {handleNPKeyNavigation} from 'utils/keyPressHandler.function';
import _ from 'lodash';
import TaskDetails from "./TaskDetails.component";
import {PATH_TO_MY_TASKS} from "components/router/paths.js";
import { launchDarkly } from "utils/launchDarkly";
import * as reduxSelectors from "components/store/application.reducers";
import { navigateBetweenTasks } from "components/mytasks/action/navigateBetweenTasks.action";


const TaskDetailMasterLayerByUID  = (props: any)=> {

    const tasks = useSelector(reduxSelectors.getTasksForRender);
    const task = useSelector(reduxSelectors.getActiveTask);
    const taskDetails = useSelector(reduxSelectors.getDetails);
    const getOneHandleOnlyFlag = useSelector(reduxSelectors.getOneHandleOnlyFlag);
    const attachments = useSelector(reduxSelectors.getTaskAttachments);
    const loadingStatus = useSelector(reduxSelectors.getAttachmentsLoadingStatus);
    const tasksWithPayment = useSelector(reduxSelectors.getTasksWithPayment);
    const useDraftComments = launchDarkly.userClient && launchDarkly.userClient.variation("fe-draft-comments", false);
    const dispatch = useDispatch();


    useEffect(() => {
        try {
            requestData(props.match.params.uid);
        } catch (e) {
            props.history("/");
            return;
        }
        dispatch(removeSelectedDocument());
        document.addEventListener('keydown', handleKeyPress);
        return () => {
           document.removeEventListener('keydown', handleKeyPress);
        }
    }, []);

    useEffect(() => {
        requestData(props.match.params.uid);
    }, [props.match.params.uid]);


    const taskNavigation = (uid: string, isForward: boolean) =>{
        props.history(dispatch(navigateBetweenTasks(uid, isForward)));
    }

    const handleKeyPress = (e) =>{
        handleNPKeyNavigation(e, props.match.params.uid, taskNavigation);
    }

     const getNavigation = () => {
        //fill in all the data found in the task
         let response = formatTaskDetailsTopBanner(task);
         let tasksAreFiltered = tasks.filteredKeys.length !== 0;

         if (!task) {
             return response;
         }
        response.companyName = task.companyName;
        //task number and number of selected tasks still need to be computed
        if (getOneHandleOnlyFlag) {
            let localTasks = tasksAreFiltered ? tasks.filteredTasks : tasks.allTasks;
            response.currentTask = _.findIndex(localTasks, ((x: any) => {
                return x.task.key === task.key;
            })) + 1;
            response.total = localTasks.length;
        } else {
            response.currentTask = (tasks.selectedKeys.indexOf(task.key) + 1);
            response.total = tasks.selectedKeys.length;
        }

        return response;
    }

    const requestData = (uid: string) => {
        return dispatch(getTaskDetailsByUID(uid));
    }

    const getActionsForTask = (navigation: any) => {
        let activeActions = new TaskDetailActions();
        if (!task) {
            return activeActions;
        }

        // map all from task
        activeActions.enableFromTaskDetail(task);

        if (task.isHandled) {
            activeActions.disableAll();
            activeActions.enable(TASK_ACTIONS.OPEN, TASK_ACTIONS.EMAIL);

        } else {
            // add open & postpone & email
            activeActions.enable(TASK_ACTIONS.OPEN, TASK_ACTIONS.POSTPONE, TASK_ACTIONS.EMAIL);

            // add attachment depending on task permission
            if (taskDetails.taskPermission?.allowUploadAttachment) {
                activeActions.enable(TASK_ACTIONS.ADD_ATTACHMENT);
            }

            if (task.preApprovedForPayment === false && tasksWithPayment.length > 0) {
                activeActions.enable(TASK_ACTIONS.APPROVE_BATCH);
            }
        }

        const isNotLastTask = navigation.currentTask !== navigation.total;
        const isNotFirstTask = navigation.currentTask !== 1;
        // Enable navigation actions
        if (isNotLastTask) activeActions.enable(TASK_ACTIONS.NEXT_TASK);
        if (isNotFirstTask) activeActions.enable(TASK_ACTIONS.PREVIOUS_TASK);
        return activeActions;
    }

    const dispatchTaskAction = (type: any, taskIds = [], options = {}, navigate = () => {
    }) => {
        dispatch(taskAction(type, taskIds, options, navigate));
    }

    const dispatchHandleAddTasksDraftComments = (taskKey: string, comment: string, documentUid: string, uid:string) => {
        dispatch(handleAddTasksDraftComments(taskKey, comment, documentUid, uid));
    }
    const dispatchHandleAddTasksComments = (taskKey:string, comment: string) => {
        dispatch(handleAddTasksComments(taskKey, comment));
    }

    const dispatchGetTaskCommentsByUid = (uid: string, skipLoading = false) => {
        dispatch(getTaskCommentsByUid(uid, skipLoading));
    }

    const dispatchGetTaskCommentsData = (taskId: string, skipLoading = false) => {
        dispatch(getTaskCommentsData(taskId, skipLoading));
    }


    const navigationData = getNavigation();
    const  activeActions = getActionsForTask(navigationData);
    const params =  {
        taskId: task?.key,
        uid: task?.uid,
    };

    return  (
        <div>
        {!task ?
            <div>
                <span className="appicon loading" />
            </div> :
            // @ts-ignore
            <TaskDetails params={params}
                         task={task}
                         taskAction={dispatchTaskAction}
                         navigation={navigationData}
                         attachments={attachments}
                         taskDetails={taskDetails}
                         loadingStatus={loadingStatus}
                         route={props.route}
                         activeActions={activeActions}
                         navigateBetweenTasks={taskNavigation}
                         navigateBackPath={PATH_TO_MY_TASKS}
                         navigateBack={() => {
                         }}
                         type={DETAILS_VIEW_TYPE.TASK}
                         routerBlocker={props.blocker}
                         allowActionlessComments={taskDetails.taskPermission?.allowExtraComments}
                         addCommentHandler={useDraftComments ? dispatchHandleAddTasksDraftComments : dispatchHandleAddTasksComments}
                         getCommentsHandler={useDraftComments ? dispatchGetTaskCommentsByUid : dispatchGetTaskCommentsData} />
        }
        </div>);
}

export default TaskDetailMasterLayerByUID;