import React, {useEffect, useRef} from "react";
import {AttachmentsForRender} from "types/attachment";
import translate from "components/translations/translations.wrapper";
import {useDispatch} from "react-redux";
import {selectedDocumentChanged} from "components/taskdetails-remake/taskDetails.action";

const MAX_TABS: number = 3;

interface CollapsableTabsProps {
    translate: React.FC;
    attachments: Array<any>;
    setActiveTab: React.FC;
    activeTab: number | null;
}

export const CollapsableTabs = ({
                                    translate,
                                    attachments,
                                    activeTab,
                                    setActiveTab
                                }: CollapsableTabsProps): React.ReactElement => {
    const dropDownRef = useRef<HTMLLIElement>(null);
    const dispatch = useDispatch();
    const contentRefs = useRef<(HTMLLIElement | null)[]>([]);
    const tabListRef = useRef<HTMLDivElement>(null);

    const handleDropDownToggle = () => {
        if (dropDownRef.current) {
            if (!dropDownRef.current.classList.contains("open"))
                window.addEventListener("click", closeOnOutsideClick);
            dropDownRef.current.classList.toggle("open");
        }
    }

    const closeOnOutsideClick = (event: MouseEvent) => {
        if (tabListRef.current && !tabListRef.current.firstChild?.contains(event.target as Node)) {
            handleDropDownClose();
        }
    }

    const handleDropDownClose = () => {
        if (dropDownRef.current)
            dropDownRef.current.classList.remove("open");
    }

    useEffect(() => {
        return () => {
            window.removeEventListener("click", closeOnOutsideClick);
        }
    });

    const handleSetActiveTab = (index: number) => {
        setActiveTab(index);
        contentRefs.current.forEach((ref, i) => {
            ref?.classList.toggle('active', index === i);
        });
        dispatch(selectedDocumentChanged(attachments[index], index));
        handleDropDownClose();
    }

    const renderTabs = (attachments: Array<AttachmentsForRender>, startIndex: number): React.ReactElement[] | React.ReactElement => {
        if (!attachments || !Array.isArray(attachments)) return <li></li>;

        return attachments.length > 0 ? attachments.map((attachment: AttachmentsForRender, index: number) => {
            return <li className={`nav-item ${(activeTab === index + startIndex) ? "active" : ""}`}
                       ref={ref => contentRefs.current[startIndex + index] = ref}
                       key={`${attachment.id}_nav-item`}>
                <a id={`normal-tab_${attachment.id}`} className={"outline-none"} tabIndex={0} role="tab"
                   data-toggle="tab" aria-controls="normal"
                   aria-selected="false"
                   data-testid={`__tst_tab_${attachment.id}`}
                   onClick={() => handleSetActiveTab(startIndex + index)}>
                    {translate("taskDetail.panels.attachments.document")} {startIndex + index + 1}
                </a>
            </li>
        }) : <li></li>;
    };

    const renderDropDown = (): React.ReactElement[] | React.ReactElement | null => {
        if (!attachments || !Array.isArray(attachments) || attachments?.length <= MAX_TABS) return null;
        return (
            <li className="dropdown tabdrop" key={`dropdown_nav-item`} ref={dropDownRef}>
                <a className="dropdown-toggle" tabIndex={0} data-toggle="dropdown"
                   onClick={handleDropDownToggle}>
                    <span className="sr-only">Toggle dropdown</span>
                </a>
                <ul className="dropdown-menu attachments-preview-modal__extra-dropdown" role="menu">
                    {renderTabs(attachments.slice(MAX_TABS, attachments.length), MAX_TABS)}
                </ul>
            </li>
        )
    }


    return (
        <div className={"w-100 dark-theme"} ref={tabListRef}>
            <ul className="nav nav-tabs attachments-preview-modal__tabs"
                role="tablist"
                aria-label="Sample Tabs"
                data-testid={"__tst_tabs"}
            >
                {renderTabs(attachments.slice(0, MAX_TABS), 0)}
                {renderDropDown()}
            </ul>
        </div>
    );
}
export default translate(CollapsableTabs);