import {checkUnevaluatedLinesProps} from "types/approvePopup";
import React from "react";

export const CheckUnevaluatedLines = ({
                                          mayHaveUnevaluatedLines,
                                          hasUnevaluatedLines,
                                          translate
                                      }: checkUnevaluatedLinesProps): React.ReactElement => {

    if (mayHaveUnevaluatedLines) {
        return (
            <div className={"d-flex align-items-center ast_mayHaveUnevaluatedLines"}>
                <span className={"vismaicon vismaicon-sm vismaicon-dynamic vismaicon-filled vismaicon-info me-3"}/>
                {translate("popUp.approve.mayHaveUnevaluatedLines")}
            </div>
        )
    }

    if (hasUnevaluatedLines) {
        return (
            <div className={"d-flex align-items-center ast_hasUnevaluatedLines"}>
                <span className={"vismaicon vismaicon-sm vismaicon-dynamic vismaicon-filled vismaicon-info me-3"}/>
                {translate("popUp.approve.youHaveUnevaluatedLines")}
            </div>
        )
    }

    return <></>;
}

export const CheckDraftComment = ({numberOfTasks, showComments, translate}): React.ReactElement => {
    if (showComments && numberOfTasks === 1) return null;

    return (
        <span className={"d-flex align-items-center mt-4"}>
            <span className={"vismaicon vismaicon-sm vismaicon-dynamic vismaicon-filled vismaicon-info me-3"}/>
            {numberOfTasks > 1 ?
                (
                    <span className={"ast_multipleTaskWithDraftComments"}>
                        <span>{translate("popUp.approve.multipleTasksWithDraftComments")}</span>
                        {showComments &&
                            <div className={"ast_commentOverrideWarning"}>
                                {translate("popUp.approve.commentOverrideWarning")}
                            </div>
                        }
                    </span>
                ) :
                <span className={"ast_singleTaskWithDraftComment"}>
                    {translate("popUp.approve.singleTaskWithDraftComment")}
                </span>
            }
        </span>
    );
}

