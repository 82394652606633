import Comment from '../input/Comment.component';
import {PopUp, VisibleToEverybody} from './PopUp.component';
import {DOCUMENT_ACTIONS} from 'utils/constants';
import translate from '../translations/translations.wrapper.jsx';
import * as Api from 'utils/api/api.js';
import {handleError} from 'utils/errorHandle.function';
import {PATH_TO_DOCUMENT_OVERVIEW} from "components/router/paths.js";
import React from "react";

//this is to make sure the tabbing stays inside the dialog
const TAB_INDEX = 1000;


export class CancelDocuments extends PopUp {

    constructor(props) {
        super(props);
        this.ok = this.ok.bind(this);
        this.buildContent = this.buildContent.bind(this);

        this.state = {
            comment: '',
            actionRunning: false // we're waiting for backend call result
        }

        this.okButton = React.createRef();
        this.commentInput = React.createRef();
    }

    ok() {
        const self = this;

        this.setState({actionRunning: true});

        const taskIds = this.props.tasks.map((task) => {
            return task.key;
        });

        let commentToSend = this.state.comment ? this.state.comment : '';

        Api.cancelDocument(this.props.companyId, taskIds[0], commentToSend).then(response => {
            self.props.showNotification(response.message);
            self.setState({actionRunning: false});
            self.props.handledTaskCallback(undefined, self.props.navigate);
            self.props.closeCallback();
            self.props.navigate(PATH_TO_DOCUMENT_OVERVIEW);
        }, error => {
            handleError(error);
        });

    }

    componentDidMount() {
        super.componentDidMount();
        this.okButton.current?.focus();
    }

    buildContent() {

        const okEnabled = !this.state.actionRunning;
        return (
            <div>
                <div className="modal-body">
                    <div>
                        <label htmlFor="comment">{this.props.translate("popUp.approve.commentLabel")}</label>
                    </div>
                    <VisibleToEverybody translate={this.props.translate}/>
                    <Comment
                        ref={this.commentInput}
                        inputHint={this.props.translate("popUp.approve.commentHint")}
                        translate={this.props.translate}
                        tabIndex={TAB_INDEX}
                        id="comment"
                        value={this.state.comment}
                        propagateValue={value => this.setState({comment: value})}
                    />
                </div>

                <div className="modal-footer">
                    <div className="float-right">
                        <button className="btn btn-primary btn-margin-right"
                                onClick={this.ok}
                                disabled={!okEnabled}
                                ref={this.okButton}
                                tabIndex={TAB_INDEX + 1}>
                            {this.props.translate("popUp.ok")}
                        </button>
                        <button className="btn btn-default"
                                onBlur={() => {
                                    this.commentInput.current?.focus();
                                }}
                                onClick={this.props.closeCallback}
                                tabIndex={TAB_INDEX + 2}>
                            {this.props.translate("popUp.cancel")}
                        </button>
                    </div>
                </div>
            </div>

        );
    }

    getType() {
        return DOCUMENT_ACTIONS.CANCEL_DIRECTLY;
    }

    getTitle() {
        return this.props.translate("popUp.cancelDocuments.title", this.props.tasks[0].description);
    }

}

const withTranslations = translate(CancelDocuments);
export default withTranslations;
