import React, {Fragment} from 'react';
import {PopUp} from './PopUp.component';
import * as Api from 'utils/api/api';
import * as reduxSelectors from '../store/application.reducers';
import {connect} from 'react-redux';
import translate from '../translations/translations.wrapper.jsx';
import {AdvancedSearch} from '../myhistory/HistoryAdvancedSearch.component';
import {handleError} from "utils/errorHandle.function";
import * as _ from "lodash";
import {fixedMenuStyle} from 'utils/reactSelectTheme';

//this is to make sure the tabbing stays inside the dialog
const TAB_INDEX = 1000;

export class AddCostUnitPopUp extends PopUp {
    constructor(props) {
        super(props);
        this.ok = this.ok.bind(this);
        this.buildContent = this.buildContent.bind(this);
        this.focusFirst = this.focusFirst.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.mapPropsToState = this.mapPropsToState.bind(this);

        this.state = {
            actionRunning: false,
            displayError: null,
            newCombination: null,
            /**
             * @type {StateCostUnitSelection}
             */
            mappedValue: []
        };
        this.firstFocus = React.createRef();
    }

    /**
     * @typedef {Array<StateCostUnitItem>} StateCostUnitSelection
     */

    /**
     * @typedef {object} StateCostUnitItem
     * @property {number} dimension - dimension number
     * @property {boolean} unassigned true means unassigned
     * @property {string=} id undefined means any
     * @property {string=} name
     */

    /**
     * cost unit selection
     * @typedef {object} CostUnitsDropDownSelection
     * @property {string=} label
     * @property {string|-99|-1} value -99: any; -1: unassigned

     /**
     *
     * @param {CostUnitsDropDownSelection|null} selected Selection in the drop-down. null means unassigned
     * @param {StateCostUnitItem} costUnit
     */
    changeValue(selected, costUnit) {

        let findIndex = _.findIndex(this.state.mappedValue, (unit) => {
            return unit.dimension === costUnit.dimension;
        });

        if (findIndex !== -1) {
            let newState = structuredClone(this.state.mappedValue);
            if (selected == null || selected.value === -1) {
                newState[findIndex].unassigned = true;
                newState[findIndex].id = undefined;
                newState[findIndex].name = this.props.translate("generic.unassigned");
            } else {
                newState[findIndex].unassigned = false;
                newState[findIndex].name = selected.label;
                newState[findIndex].id = (selected.value === -99) ? undefined : "" + selected.value;
            }

            this.setState({
                mappedValue: newState
            });
        }

    }

    ok() {
        /**
         * @type {import('../../utils/api/api').AddCostUnitNodeRequest}
         */
        let toReturn = {
            costUnits: {}
        };

        this.state.mappedValue.forEach(values => {
            if (!values.unassigned) {
                toReturn.costUnits[values.dimension] = values.id != null ? values.id : null;
            }
        });

        Api.saveCostUnitNodes(toReturn, this.props.treeVersion).then(response => {
            this.props.handleAction(response);
        }, error => {
            if (error.errorcode === 409) {
                error.errorMessages = this.props.translate("costUnitApprovers.wrongVersion");
                this.props.getApproversTreeRoot();
            }
            handleError(error);
            this.props.closeCallback();
        });

        this.setState({actionRunning: true});
    }

    componentDidMount() {
        super.componentDidMount();
        this.mapPropsToState();
        if (Object.keys(this.props.selectedNodes).length === 1 && this.props.selectedNodes[0].id === null)
            setTimeout(() => {
                this.focusFirst();
            }, 10);
    }

    focusFirst() {
        if (this.firstFocus.current) {
            this.firstFocus.current.focus();
        }
    }

    mapPropsToState() {
        /**
         * Selected node
         * @typedef {Object} SelectedNode
         * @property {number} dimensionNumber
         * @property {string} name
         * @property {string=} id - undefined means unassigned
         */

        /**
         * Cost unit type
         * @typedef {Object} CostUnitType
         * @property {string} name
         * @property {number} dimension
         */

        /*
        * @type {Array<CostUnitType>}
        */
        const costUnitTypes = this.props.costUnits;
        /**
         * @type {Object<number, SelectedNode>}
         */
        const selectedNodes = this.props.selectedNodes;

        if (costUnitTypes != null && costUnitTypes.length > 0 && selectedNodes != null) {
            /**
             * @type {StateCostUnitSelection}
             */
            let newState = costUnitTypes.map(( /** @type {CostUnitType} */costUnitType,
                                               /** @type {number} */ index) => {
                let selectedNode = selectedNodes[index];

                /**
                 * @type {StateCostUnitItem}
                 */
                let returnValue = {
                    dimension: costUnitType.dimension,
                    unassigned: (selectedNode == null)
                };
                if (selectedNode != null) {
                    returnValue.id = (selectedNode.id != null) ? selectedNode.id : undefined;
                    returnValue.name = selectedNode.name;
                }
                return returnValue;
            });

            this.setState({
                mappedValue: newState
            });
        }
    }

    buildContent() {
        /*
        * @type {Array<CostUnitType>}
        */
        const costUnitTypes = this.props.costUnits;

        return (
            <Fragment>
                <div className="modal-body add-cost-unit-combination__body overflow-auto">
                    {this.state.mappedValue && this.state.mappedValue.map((/**@type {StateCostUnitItem}*/costUnit, index) => {
                        /**
                         * @type {CostUnitsDropDownSelection}
                         */
                        let selected = {
                            value: -1,
                            label: this.props.translate("generic.unassigned")
                        };
                        let canEdit = index > 0 ? !this.state.mappedValue[index - 1].unassigned : true;

                        let costUnitType = costUnitTypes[index];
                        if (!costUnit.unassigned) {
                            selected.label = costUnit.name;
                            selected.value = costUnit.id == null ? -99 : costUnit.id;
                        }

                        return (<div key={index}>
                            <AdvancedSearch key={index}
                                            ref={index === 0 ? this.firstFocus : null}
                                            bsClass="col-md-12 px-0"
                                            disabled={!canEdit}
                                            autoFocus={canEdit}
                                            placeholder={""}
                                            styles={fixedMenuStyle}
                                            name={costUnitType.name}
                                            type={Api.lookupCostCenters}
                                            usedInIndexOnly={false}
                                            selectedValue={selected}
                                            dimension={costUnit.dimension}
                                            hasAny={true}
                                            hasUnassigned={true}
                                            tabIndex={selected ? TAB_INDEX + index : undefined}
                                            onChange={(value) => {
                                                this.changeValue(value, costUnit)
                                            }}
                                            tabSelectsValue={false}
                                            translate={this.props.translate}/>
                        </div>)
                    })}
                </div>
                <div className="modal-footer">
                    <div className="float-end">
                        <button className="btn btn-primary btn-margin-right"
                                onClick={this.ok}
                                ref={(element) => {
                                    this.okButton = element;
                                }}
                                tabIndex={TAB_INDEX + this.state.mappedValue.length}>{this.props.translate("popUp.ok")}</button>
                        {!this.props.hideClose &&
                            <button className="btn btn-default"
                                    onClick={this.props.closeCallback}
                                    onBlur={this.focusFirst}
                                    tabIndex={TAB_INDEX + this.state.mappedValue.length + 1}>{this.props.translate("popUp.cancel")}</button>
                        }
                    </div>
                </div>
            </Fragment>
        );
    }

    getType() {
        return "addCostUnitCombinations";
    }

}


const
    mapStateToProps = function (store) {
        return {
            selected: reduxSelectors.getSelectedDocument(store)
        };
    };
const
    withTranslations = translate(AddCostUnitPopUp);
const
    connected = connect(mapStateToProps, {})(withTranslations);

export default connected;
