import {AttachmentsForRenderProps} from "types/attachment";
import React, {useEffect} from "react";
import * as pdfjs from 'pdfjs-dist/legacy/build/pdf.min.mjs';
import {changeImageZoomPercentage} from "components/taskdetails-remake/taskDetails.action";
import Menu from "components/taskdetails-remake/panels/attachments/attachmentTypes/Images/Menu.function";
import {useSelector} from "react-redux";
import {Store} from "redux";
import * as reduxSelectors from "components/store/application.reducers";
import translate from "components/translations/translations.wrapper";
import AutoSizer from "react-virtualized/dist/es/AutoSizer";
import List from "react-virtualized/dist/es/List";
import {PDF_BASE} from "utils/constants";
import AlternativePageView
    from "components/taskdetails-remake/panels/attachments/attachmentTypes/AlternativePageView.function";
import closeIcon from "assets/Icon-16-close.svg";

const OVERSCAN_ROW_COUNT = 5;
const IMG_MARGIN_BOTTOM = 16;

export const urlToBinary = async (url: string): Promise<ArrayBuffer> => {
    const encoder = await fetch(url);
    const arrayBuffer = await encoder.arrayBuffer();

    return arrayBuffer;
}


const getCookie = (key) => {
    let valueArray = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return valueArray ? valueArray.pop() : "";
}

export const PdfAlternativeView = ({url, translate, ownerDocument}: AttachmentsForRenderProps): React.ReactElement => {
    pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/legacy/build/pdf.worker.mjs', import.meta.url).toString();

    const zoom = useSelector((store: Store) => reduxSelectors.getImageZoomPercentage(store));
    const [pdf, setPdf] = React.useState<any>();
    const [totalPages, setTotalPages] = React.useState<number>(1);
    const [pageInView, setPageInView] = React.useState<number>(1);
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [goToPage, setGoToPage] = React.useState<number | null>(null);
    const [rotation, setRotation] = React.useState<number>(0);

    const alternativeContainerRef = React.createRef<HTMLDivElement>();
    const toastRef= React.createRef<HTMLDivElement>();

    const navigateToPage = (pageNumber: number) => {
        if (pageNumber <= totalPages && pageNumber > 0) {
            setPageInView(pageNumber - 1);
            setGoToPage(pageNumber - 1);
        }
    }

    const handleScroll = (event: any) => {
        const pageHeight = zoom ? ((zoom * PDF_BASE.height) / 100) - IMG_MARGIN_BOTTOM : PDF_BASE.height;
        let currentPage = parseInt(String((event.scrollTop / pageHeight)), 10) + 1;
        if (currentPage <= totalPages && currentPage > 0) {
            setPageInView(currentPage);
        }
    }

    const rotateImage = () => {
        let newRotation = rotation === 270 ? 0 : rotation + 90;
        setRotation(newRotation);
    };


    useEffect(() => {
        if (url && !pdf) {
            urlToBinary(url).then(function (arrayBuffer) {
                let documentInitParams = {data: arrayBuffer};
                if(ownerDocument) {
                    documentInitParams = {...documentInitParams, ...ownerDocument};
                }
                let attachment = pdfjs.getDocument(documentInitParams);
                attachment.promise.then(function (pdf) {
                    setPdf(pdf);
                    setTotalPages(pdf.numPages);

                }).catch(function (error) {
                    setErrorMessage(translate("taskDetail.panels.attachments.unavailableDetails"));
                });
            });
        }
    }, [url]);

    useEffect(() => {
        if (pdf && pageInView <= totalPages) {
            navigateToPage(pageInView);
        }
    }, [zoom]);

    const _rowRender = ({index, key, style}: any) => {
        return <AlternativePageView index={index} key={key} style={style} pdf={pdf} zoom={zoom} rotation={rotation}/>
    }

    const closeToast = () => {
        //add 30 days cookie
        document.cookie = "alt-pdf-info=false; max-age=30*24*60*60; path=/";
        toastRef.current?.classList.add("d-none");
    }


    return <div className={"attachment-image-container h-100"} ref={alternativeContainerRef}>
        {!navigator?.pdfViewerEnabled && getCookie("alt-pdf-info") !== "false" &&
            <div className={"right-4 bottom-4 w-75 position-absolute h-25"} ref={toastRef}>
                <div
                    className={"toast toast-info w-100 right-4 alternative-pdf-toast"}
                    role="alert">
                    <img className={"close-icon cursor-pointer top-16 right-16 position-absolute"} src={closeIcon}
                         alt={"close"} onClick={closeToast}/>
                    <div className={"mb-2"}><strong>{translate("taskDetail.altPdfToast.title")}</strong></div>
                    <span>
                    {translate("taskDetail.altPdfToast.body")}
                    </span>
                </div>
            </div>
        }

        <Menu zoomPercentage={zoom} pageInView={pageInView} totalImages={totalPages} rotateImage={rotateImage}
              changeZoom={changeImageZoomPercentage} scrollToImage={navigateToPage} hasZoomToFit={false}/>
        <div className={"alternative-pdf-container bg-natural-white py-2 rounded-8"}>
            {!pdf &&
                <span className={"spinner spinner-default-blue loading top-50-percent"}/>
            }
            {pdf &&
                <AutoSizer className="h-100 w-100">
                    {({width, height}: any) => (
                        <List
                            className="w-100"
                            height={height || 500}
                            overscanRowCount={OVERSCAN_ROW_COUNT}
                            rowCount={totalPages}
                            rowHeight={(zoom * PDF_BASE.height) / 100 + IMG_MARGIN_BOTTOM}
                            rowRenderer={_rowRender}
                            id={"CANVAS_LIST"}
                            width={width || 500}
                            scrollToAlignment="start"
                            onScroll={handleScroll}
                            scrollToIndex={goToPage ? goToPage : undefined}
                        />
                    )}
                </AutoSizer>
            }

            {errorMessage &&
                <h3 className={"color-lightgrey"}>{errorMessage}</h3>
            }
        </div>
    </div>
}


export default translate(PdfAlternativeView);

