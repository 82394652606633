import React, {Component, Fragment} from 'react';
import lightBulg from "./icons/24_light_bulb.svg";
import {Tooltip} from "../tooltip/Tooltip";
import Onboarding from "../popup/OnboardingPopop.component";
import {snowplowTrackEvent} from "utils/snowplow";
import picture from "components/taskdetails-remake/icons/Pictogram-selected-invoice.svg";
import _ from "lodash";

const showOnboardingEvent = "showOnboardingEvent";
const closeOnboardingEvent = "closeOnboardingEvent"
const onboardingPopup = localStorage.getItem('onboarding/popup/dontShowAgain');
const showFirstTimeOnboarding = localStorage.getItem('onboarding/popup/firstTime');
const forceOnboarding = localStorage.getItem('onboarding/popup/newOnboarding');

const bypassCIROnboarding = localStorage.getItem('onboarding/popup/bypassCIROnboarding');
const hideOnboardingCIR = localStorage.getItem('onboarding/popup/hideOnboarding');

const bypassCIROnboardingNew = localStorage.getItem('onboarding/popup/bypassCIROnboardingNew');
const hideOnboardingCIRNew = localStorage.getItem('onboarding/popup/hideOnboardingNew');

const detachOnboarding = localStorage.getItem('onboarding/popup/detachOnboarding');
const hideDetachOnboarding = localStorage.getItem('onboarding/popup/hideDetachOnboarding');

export class OnboardingTaskDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showOnboardingPopup: false,
            showOnboardingTooltip: false,
            showNotification: false, //hideDetachOnboarding !== "true" && !detachOnboarding,
            displayModal: forceOnboarding !== "true"
        };
        this.onboardingIcon = React.createRef();
        this.showOnboarding = this.showOnboarding.bind(this);
        this.closeOnboarding = this.closeOnboarding.bind(this);
        this.dispatchOnboarding = this.dispatchOnboarding.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.dismiss = this.dismiss.bind(this);
        this.openOnboardingModal = this.openOnboardingModal.bind(this);
    }

    componentDidMount() {
        //clear old cookies
        if (onboardingPopup)
            localStorage.removeItem('onboarding/popup/dontShowAgain');
        if (forceOnboarding)
            localStorage.removeItem('onboarding/popup/newOnboarding');
        if (showFirstTimeOnboarding)
            localStorage.removeItem('onboarding/popup/firstTime');
        if(bypassCIROnboarding)
            localStorage.removeItem('onboarding/popup/bypassCIROnboarding');
        if(hideOnboardingCIR)
            localStorage.removeItem('onboarding/popup/hideOnboarding');
        if(bypassCIROnboardingNew)
            localStorage.removeItem('onboarding/popup/bypassCIROnboardingNew');
        if(hideOnboardingCIRNew)
            localStorage.removeItem('onboarding/popup/hideOnboardingCIRNew');
        //end clear old cookies

        // if (!detachOnboarding) {
        //     window.localStorage.setItem('onboarding/popup/detachOnboarding', true);
        //     this.dispatchOnboarding();
        // }
        /* if(this.state.displayModal){
             this.openOnboardingModal();
             window.localStorage.setItem('onboarding/popup/newOnboarding', true);
         }*/
    }

    showOnboarding() {
        const onboardingPopup = localStorage.getItem('onboarding/popup/hideDetachOnboarding');
        if (onboardingPopup === 'true') {
            return;
        }
        this.dispatchOnboarding();
    }

    dispatchOnboarding() {
        this.setState({
            showOnboardingTooltip: true
        }, () => {
            this.onboardingIcon.current?.dispatchEvent(new Event(showOnboardingEvent));
        })

    }

    closeOnboarding() {
        this.setState({showOnboardingPopup: false, showOnboardingTooltip: false, showNotification: false});
        this.onboardingIcon.current?.dispatchEvent(new Event(closeOnboardingEvent));
    }

    handleBlur() {
        if (!this.state.showOnboardingPopup) {
            this.setState({showOnboardingTooltip: false});
            this.onboardingIcon.current?.dispatchEvent(new Event(closeOnboardingEvent));
        }
    }

    dismiss() {
        this.setState({
            showNotification: false,
            showOnboardingTooltip: false
        })
        this.onboardingIcon.current?.dispatchEvent(new Event(closeOnboardingEvent));
    }

    openOnboardingModal() {
        this.setState({showOnboardingPopup: true})
        snowplowTrackEvent({
            category: "modular_onboarding",
            action: "learn_more",
            label: "modular_onboarding_more",
            value: true
        }, this.props.userData);
    }

    render() {

        const content = _.times(3, (index) => {
            let time = index + 1;
            return <p>{this.props.translate("menu.onboarding.content" + time)}</p>
        });

        return (
            <Fragment>
                <li className={"icon " + (this.state.showNotification ? "attention" : "")}
                    data-for={"onboarding-tooltip"} data-tip ref={this.onboardingIcon}>
                    <a className="button-help" href="#"
                       onClick={this.state.showOnboardingTooltip ? this.closeOnboarding : this.dispatchOnboarding}>
                        <img alt={"light bulb"} src={lightBulg} className={"appicon vismaicon-dynamic border-0"}/>
                    </a>
                </li>

                {this.state.showOnboardingTooltip &&
                    <Tooltip
                        place="bottom"
                        id={"onboarding-tooltip"}
                        event={showOnboardingEvent}
                        eventOff={closeOnboardingEvent}
                        borderClass = {"border-onboarding-tooltip"}
                        content={() => {
                            return <div onMouseLeave={this.handleBlur}
                                        className={"d-flex onboarding__tooltip__content rounded-8"}>
                                <img alt={"work from home"} src={picture} width={69}
                                     className={"border-0 pull-left onboarding__title__image"}/>

                                <div className={"pull-right onboarding__tooltip__body ml-12"}>
                                    <h3 className={"d-flex my-3"}>
                                        <strong>
                                            {this.props.translate("menu.onboarding.title")}
                                        </strong>
                                    </h3>
                                    <div>
                                        {content}
                                    </div>

                                    <div className={"mt-24 d-flex justify-content-between"}
                                         style={{ lineHeight: "36px" }}>
                                        {/*<button className={"btn btn-default cursor-pointer d-none"}*/}
                                        {/*        autoFocus={true} onBlur={this.handleBlur}*/}
                                        {/*        onClick={this.openOnboardingModal}>*/}
                                        {/*    {this.props.translate("menu.onboarding.learnMore")}*/}
                                        {/*</button>*/}
                                        <button className={"btn btn-default"}
                                                autoFocus={true} onBlur={this.handleBlur}
                                                onClick={this.closeOnboarding}>
                                            {this.props.translate("menu.onboarding.ok")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }}
                    />
                }

                {this.state.showOnboardingPopup &&
                    <Onboarding loggedInData={this.props.loggedInData}
                                userData={this.props.userData}
                                closeCallback={this.closeOnboarding}/>
                }
            </Fragment>
        )
    }
}

export default OnboardingTaskDetails;
