import React, {useEffect, useState} from "react";
import FetchAttachmentBlobFunction from "./FetchAttachmentBlob.function";
import {AttachmentRendererProps, RendererTypes} from "types/attachment";
import Image from "./attachmentTypes/Images/Image.function";
import Pdf from "./attachmentTypes/Pdf.function";
import Html from "./attachmentTypes/Html.function";
import PDFAlternativeView from "./attachmentTypes/PdfAlternativeView.function";
import Unknown from "./attachmentTypes/Unknown.function";
import {IMAGE_MIME_TYPE} from "utils/constants";
import {BypassCIRSkeletonContent} from "./BypassCIR.Skeleton";
import Immutable from "seamless-immutable";
import AttachmentInformation from "components/taskdetails-remake/panels/attachments/AttachmentInformation";
import {useSelector} from "react-redux";
import {Store} from "redux";
import * as reduxSelectors from "components/store/application.reducers";
import {snowplowTrackEvent} from "utils/snowplow";


const MimeTypeRenderer: RendererTypes = Immutable.from({
    "application/pdf": Pdf,
    "text/html": Html,
    "image/png": Image,
    "image/jpeg": Image,
    "image/jpg": Image,
    "image/tiff": Image,
    "text/xml": Unknown,

    asComponent(key: string) {
        return this.hasOwnProperty(key) ? this[key] : Unknown;
    }
});


const AttachmentRenderer = ({
                                attachment,
                                isPanelCollapsed,
                                collapseCallback,
                                ownerDocument
                            }: AttachmentRendererProps): React.ReactElement => {
    const [url, setUrl] = useState<string>("");
    const userData = useSelector((store: Store) => reduxSelectors.getUsersData(store));
    const showDisplayAlternativePdfViewer = !navigator?.pdfViewerEnabled || (window.localStorage.getItem("@@pdf/defaultPDF") && window.localStorage.getItem("@@pdf/defaultPDF") !== "true");

    const getAttachmentType = () => {
        if (attachment.mimeType === null || !attachment.mimeType)
            return Unknown;

        if (attachment.mimeType.includes("pdf") && showDisplayAlternativePdfViewer) {
            return PDFAlternativeView;
        }

        return MimeTypeRenderer.asComponent(attachment.mimeType) || BypassCIRSkeletonContent;
    }

    const loadAttachments = () => {
        FetchAttachmentBlobFunction(attachment.originalUrl, attachment.mimeType, userData).then((result: string) => {
            setUrl(result)
        });
    }
    useEffect(() => {
        if (attachment.mimeType === null || !attachment.mimeType)
            return;
        if (!attachment.mimeType.includes(IMAGE_MIME_TYPE) && attachment.urlsOriginalJson && url === "") {
            loadAttachments();
        }

        if (showDisplayAlternativePdfViewer) {
            snowplowTrackEvent({
                category: "alternative-pdf-option",
                action: "select-alternative-pdf-option",
                label: "alternative-pdf-option",
                value: showDisplayAlternativePdfViewer.toString()
            }, userData);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const AttachmentRenderer = getAttachmentType();
    const hasAttachmentInformation = attachment.information?.uploaderId !== 0;
    const collapsedClass = isPanelCollapsed ? 'info-collapsed' : 'info-expanded';
    const attachmentRendererWrapperClass = hasAttachmentInformation ? `pt-3 attachment-container-with-info ${collapsedClass}` : "h-100";

    return (
        <div className={`h-100 p-3 mt-0 ${hasAttachmentInformation ? "pt-12" : ""}`}>
            {hasAttachmentInformation &&
                <AttachmentInformation {...attachment.information}
                                       canBeDeleted={attachment.deleteUrlTemplate}
                                       isPanelCollapsed={isPanelCollapsed}
                                       collapseCallback={collapseCallback}/>}
            <div
                className={attachmentRendererWrapperClass}>
                <AttachmentRenderer collection={attachment} url={url} callback={loadAttachments}
                                    ownerDocument={ownerDocument}/>
            </div>
        </div>
    )
}
export default AttachmentRenderer;




