import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import * as Api from '../../utils/api/api.js';

import Select, {components} from 'react-select';
import {advancedHistoryStyle} from "utils/reactSelectTheme";
import _ from "lodash";

/*
* https://github.com/phunware/react-select because react-select doesn't trigger onMenuScrollToBottom
* */

const COMMON_PARAMS = {
    prefix: '',
    page: 1,
    rows: 20,
    usedInIndexOnly: true,
    showInactive: true
};


const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <span className="advanced-search-icon mx-3"/>
        </components.DropdownIndicator>
    );
};

const Option = (props) => {
    return (
        <components.Option {...props}>
             <span style={{color: props.data.color, width: "100%", height: "20px", lineHeight: "20px"}}
                   title={props.data.label}
                   className="cropped-text py-0">{props.data.label}</span>
        </components.Option>
    );
};


export class AdvancedSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            selectedAccount: '',
            currentPage: COMMON_PARAMS.page,
            records: 1,
            isLoading: false
        };
        this.requests = [];
        this.getOptions = this.getOptions.bind(this);
        this.filterOptions = this.filterOptions.bind(this);
        this.getOptionsWithDelay = _.debounce((event, func, dimension, value) => {
            this.filterOptions(func, dimension, value)
        }, 1000);
        this.cancelCalls = this.cancelCalls.bind(this);
        this.scroll = this.scroll.bind(this);
        this.callBackend = this.callBackend.bind(this);
        this.focus = this.focus.bind(this);
        this.inputElement = React.createRef();
    }


    focus() {
        this.inputElement.focus();
    }

    filterOptions(func, dimension, value) {
        if (this.state.records > 20) {
            this.getOptions(func, dimension, value);
        }
    }

    componentWillUnmount() {
        this.cancelCalls();
        this.getOptionsWithDelay.cancel();
    }

    cancelCalls() {
        this.requests.forEach(p => {
            if (p)
                p.cancel();
        });
        this.requests = [];
    }

    callBackend(functionName, data, dimension) {
        functionName(data, dimension).then(response => {
            let values = response.rows;
            if (values && values.length > 0) {
                if (this.props.hasAny)
                    values.unshift({number: -99, name: this.props.translate("generic.genericAny"), active: true});
                if (this.props.hasUnassigned)
                    values.unshift({number: -1, name: this.props.translate("generic.unassigned"), active: true});
            }

            const results = values.map(item => {
                return {
                    label: (item.number > 0 || typeof item.number === 'string' ? (item.number + " - ") : "") + item.name,
                    value: item.number,
                    color: (item.active) ? 'black' : 'lightgrey'
                }
            });
            this.setState({
                options: results,
                isLoading: false,
                currentPage: response.page,
                records: response.records
            });
        })
    }

    getOptions(func, dimension, value) {
        this.setState({
            options: [],
            isLoading: true
        }, () => {
            if (typeof dimension === "undefined")
                return [];

            let data = Object.assign({}, COMMON_PARAMS);
            data.usedInIndexOnly = this.props.usedInIndexOnly === false ? false : COMMON_PARAMS.usedInIndexOnly;

            if (value) {
                data.prefix = value;
            }

            this.requests.push(this.callBackend(func, data, dimension));
        });
    }

    scroll(func, dimension, value) {
        if (this.state.records > this.state.options.length) {
            let data = Object.assign({}, COMMON_PARAMS);
            if (this.props.usedInIndexOnly === false)
                data.usedInIndexOnly = false;
            if (value)
                data.prefix = this.typedValue;
            data.page = this.state.currentPage + 1;

            let self = this;

            return func(data, dimension).then(response => {
                const results = response.rows.map(item => {
                    return {
                        label: "[" + item.number + "] " + item.name,
                        value: item.number,
                        color: (item.active) ? 'black' : 'lightgrey'
                    }
                });
                this.setState({
                    options: Object.assign([], self.state.options).concat(results),
                    currentPage: response.page,
                    records: response.records
                });
            });
        }
    }


    render() {
         return (
             <div aria-labelledby={"search" + this.props.name + "txt"} role="listitem"
                         className={this.props.bsClass ? this.props.bsClass : "col-md-3 ps-0 pe-5"}>
                        <div className="col-md-12 px-0 row mx-0">
                            {this.props.name &&
                                <span id={"search" + this.props.name + "txt"}
                                      className={(this.props.namePosition === 'top' ? "col-md-12" : "col-md-4") + " p3-4 text-right margin-bottom-10 cropped-text"}
                                      style={{ paddingTop: '9px' }} title={this.props.name}>{this.props.name}</span>
                            }
                            <div
                                className={(!this.props.name || this.props.namePosition === 'top' ? "col-md-12" : "col-md-8") + " px-0 margin-bottom-10 advanced-history"}>
                                <Select
                                    isClearable={true}
                                    ref={(element) => {
                                        this.typedValue = element ? element.state.inputValue : '';
                                        this.inputElement = element;
                                    }}
                                    aria-labelledby={"search" + this.props.name + "txt"}
                                    isDisabled={this.props.disabled}
                                    components={{ DropdownIndicator, Option }}
                                    styles={this.props.styles ? this.props.styles : advancedHistoryStyle}
                                    onBlurResetsInput={false}
                                    onCloseResetsInput={false}
                                    searchable={true}
                                    noResultsText={this.state.isLoading ? this.props.translate("userSearch.loading") : this.props.translate("userSearch.noresults")}
                                    value={this.props.selectedValue}
                                    placeholder={this.props.placeholder}
                                    noOptionsMessage={() => {
                                        return ""
                                    }}
                                    menuPlacement={this.props.menuPlacement ? this.props.menuPlacement : "bottom"}
                                    menuPosition={this.props.menuPosition ? this.props.menuPosition : "absolute"}
                                    tabIndex={this.props.tabIndex + ""}
                                    autoFocus={this.props.autoFocus}
                                    options={this.state.options}
                                    tabSelectsValue={this.props.tabSelectsValue ? this.props.tabSelectsValue : false}
                                    onMenuOpen={this.getOptions.bind(this, this.props.type, this.props.dimension)}
                                    onInputChange={this.getOptionsWithDelay.bind(null, this, this.props.type, this.props.dimension)}
                                    onChange={this.props.onChange.bind(this)}
                                    onMenuScrollToBottom={this.scroll.bind(this, this.props.type, this.props.dimension)}
                                    closeMenuOnSelect={true}
                                />
                            </div>
                        </div>
                    </div>

        );
    }
    }
