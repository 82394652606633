import React from 'react';
import SplitButton from '../../input/SplitButton.component';
import {TASK_ACTIONS, DETAILS_VIEW_TYPE, DOCUMENT_ACTIONS} from "utils/constants.js";
import {Tooltip} from "../../tooltip/Tooltip";
import {eventBus} from "../../pagelayout/EventBus";
import {getTaskPreviewTooltip} from "utils/tooltips.js";
import {useNavigate} from "react-router-dom";
import {launchDarkly} from "utils/launchDarkly";

export const TaskDetailsButtons = ({
                                       actions,
                                       selectedTaskKeys,
                                       action,
                                       translate,
                                       userSettings,
                                       openEditorPopup,
                                       isReadOnly,
                                       disableAll,
                                       documentVersion,
                                       task,
                                       taskDetails,
                                       hasDraftComment
                                   }) => {
    const editorAllowed = openEditorPopup ? true : false;
    const allDisabledClass = disableAll ? "disabled" : "";
    let isApproveEnabled = actions.isEnabled(TASK_ACTIONS.APPROVE);
    let isApproveAndBatchEnabled = actions.isEnabled(TASK_ACTIONS.APPROVE_BATCH);
    let isApprovePaymentEnabled = isApproveAndBatchEnabled;

    let isRejectEnabled = actions.isEnabled(TASK_ACTIONS.REJECT);
    let editorTranslation = isReadOnly ? "taskDetail.actionBar.view" : "taskDetail.actionBar.edit";
    let approveLabel = userSettings.showCommentOnApprove ? "..." : '';

    const navigate = useNavigate();

    const hasUnevaluatedLines = taskDetails.taskPermission?.hasLinesNotEvaluated;

    let actionList = actions.getSplitButtons().map((actionItem) => {

            // split button item
            const res = {
                type: actionItem.type,
                active: actionItem.active,
                shortKey: actionItem.shortKey,
                onClick: () => {
                    action(actionItem.type, selectedTaskKeys);
                },
                label: translate("taskDetail.actionBar." + actionItem.type)
            };
            return res;
        }
    );

    let approveAndSignActions = [];

    if (isApproveAndBatchEnabled) {
        approveAndSignActions = actions.getApproveAndBatchSplitButtons();
    }

    let approveAndSignList = approveAndSignActions.map((actionItem) => {
        // split button item
        const res = {
            type: actionItem.type,
            active: actionItem.active,
            shortKey: actionItem.shortKey,
            onClick: () => {
                action(actionItem.type, selectedTaskKeys);
            },
            label: translate("taskDetail.actionBar." + actionItem.type),
            tooltip: translate("taskDetail.actionBar." + actionItem.type + "Tooltip")
        };
        return res;
    });

    let addToBundle = function () {
        action(TASK_ACTIONS.APPROVE_BATCH, selectedTaskKeys);
    };

    const tooltipPreview = getTaskPreviewTooltip({
        pastActions: taskDetails.pastActions,
        actorType: task.actorType,
        taskAssignmentType: task.taskAssignmentType
    }, translate);

    return (
        <div
            className={"subnavbar-task-action-buttons d-flex align-items-center justify-content-end" + allDisabledClass}>
            <div className="d-flex align-items-center">
                {tooltipPreview.length === 0 ? null :
                    <div>
                        <span className="cursor-pointer"
                              data-for={"preview-tooltip"} data-tip>
                                         <div className="vismaicon vismaicon-dynamic vismaicon-info me-3 mt-2"/>
                        </span>
                        <Tooltip
                            id={"preview-tooltip"}
                            content={() => <PreviewTooltip translate={translate}
                                                           content={tooltipPreview}
                            />}
                        />
                    </div>}

                {isApprovePaymentEnabled &&
                    <SplitButton
                        id="ApproveAndSignSplitButton"
                        hasDefaultAction={true}
                        defaultAction={addToBundle}
                        translate={translate}
                        tooltip={translate("taskDetail.actionBar.batchTooltip")}
                        initialLabel={translate("taskDetail.actionBar.approveAndBatch")}
                        bsClass={"approve-and-sign btn-group btn-split btn-split-primary px-0 mb-0"}
                        actions={approveAndSignList}
                        disabled={disableAll}
                    />
                }

                {isApproveEnabled && !isApprovePaymentEnabled &&
                    <button
                        className={"btn btn-primary ast_approve_btn me-2 mb-0"}
                        type="button"
                        disabled={disableAll}
                        onClick={() => {
                            action(TASK_ACTIONS.APPROVE, selectedTaskKeys, {
                                documentVersion,
                                hasUnevaluatedLines,
                                hasDraftComment
                            }, navigate);
                        }}
                        data-cy="taskDetail.actionBar.approve">
                        {translate("taskDetail.actionBar.approve") + approveLabel}
                    </button>

                }
                {isRejectEnabled &&
                    <button
                        className={"btn btn-default ast_reject_btn mb-0"}
                        type="button"
                        disabled={disableAll}
                        onClick={() => {
                            action(TASK_ACTIONS.REJECT, selectedTaskKeys, {}, navigate);
                        }}
                        data-cy="taskDetail.actionBar.reject">
                        {translate("taskDetail.actionBar.reject")}
                    </button>
                }

                {editorAllowed &&
                    <button
                        className="btn btn-default mb-0"
                        type="button"
                        disabled={disableAll}
                        onClick={openEditorPopup}
                        data-cy="taskDetail.actionBar.edit">
                        {translate(editorTranslation)}
                    </button>
                }
            </div>
            <div className="d-flex align-items-center ms-4">
                <SplitButton
                    id="task-detail-split-button"
                    initialLabel={translate('taskDetail.actionBar.other')}
                    bsClass={"dropup px-0 mb-0"}
                    actions={actionList}
                    disabled={disableAll}
                    isDetails={true}
                />
            </div>
        </div>

    )
};


const TaskReviewDetailsButtons = ({
                                      actions,
                                      selectedTaskKeys,
                                      action,
                                      translate,
                                      task,
                                      taskDetails,
                                      disableAll
                                  }) => {
    const tooltipPreview = getTaskPreviewTooltip({
        pastActions: taskDetails.pastActions,
        actorType: task.actorType,
        taskAssignmentType: task.taskAssignmentType
    }, translate);

    const navigate = useNavigate();

    let actionList = actions.getReviewTaskSplitButtons().map((actionItem) => {
            // split button item
            const res = {
                type: actionItem.type,
                active: actionItem.active,
                onClick: () => {
                    action(actionItem.type, selectedTaskKeys, {}, navigate);
                },
                label: translate("taskDetail.actionBar." + actionItem.type)
            };
            return res;
        }
    );

    return (
        <div className="subnavbar-task-action-buttons d-flex align-items-center justify-content-end">
            <div className="d-flex align-items-center justify-content-end" role={"menubar"}>
                {tooltipPreview.length === 0 ? null :
                    <div>
                        <span className="cursor-pointer"
                              data-for={"preview-tooltip"} data-tip>
                            <div className="vismaicon vismaicon-dynamic vismaicon-info me-3 mt-2"/>
                        </span>
                        <Tooltip
                            id={"preview-tooltip"}
                            content={() => <PreviewTooltip translate={translate}
                                                           content={tooltipPreview}
                            />}
                        />
                    </div>}
                <button
                    className="btn btn-primary mb-0 me-2"
                    type="button"
                    disabled={disableAll}
                    onClick={() => {
                        action(TASK_ACTIONS.REVIEW, selectedTaskKeys, {}, navigate);
                    }}>
                    {translate("taskDetail.actionBar.review")}
                </button>

            </div>
            <div className="d-flex align-items-center">
                <SplitButton
                    id="task-detail-split-button"
                    initialLabel={translate('taskDetail.actionBar.other')}
                    bsClass={"dropup mb-0"}
                    actions={actionList}
                    disabled={disableAll}
                />
            </div>

        </div>
    )
};

//TO DO styling
const DocumentDetailButtons = ({
                                   actions,
                                   selectedTaskKeys,
                                   action,
                                   translate,
                                   openEditorPopup,
                                   isReadOnly,
                                   disableAll
                               }) => {

    const editorAllowed = !!openEditorPopup;
    const allDisabledClass = disableAll ? "disabled" : "";
    let isSendForApprovalEnabled = actions.isEnabled(DOCUMENT_ACTIONS.SEND_FOR_APPROVAL);
    let editorTranslation = isReadOnly ? "taskDetail.actionBar.view" : "taskDetail.actionBar.edit";
    const navigate = useNavigate();

    const cancelDocumentsEnabled = launchDarkly.companyClient?.variation("enable-cancel-documents", false);
    const listToIgnore = cancelDocumentsEnabled ? [] : DOCUMENT_ACTIONS.CANCEL_DIRECTLY;


    let actionList = actions.getDocumentSplitButtons(listToIgnore).map((actionItem) => {

            // split button item
            const res = {
                type: actionItem.type,
                active: actionItem.active,
                shortKey: actionItem.shortKey,
                onClick: () => {
                    action(actionItem.type, selectedTaskKeys, {}, navigate);
                },
                label: translate("taskDetail.actionBar." + actionItem.type)
            };
            return res;
        }
    );

    return (
        <div
            className={"text-center subnavbar-task-action-buttons d-flex align-items-center justify-content-end " + allDisabledClass}>
            <div className="">
                <SplitButton
                    id="task-detail-split-button"
                    initialLabel={translate('taskDetail.actionBar.other')}
                    bsClass={"dropdown dropup px-0 mb-0 "}
                    actions={actionList}
                    isDetails={true}
                    disabled={disableAll}
                />
            </div>

            <div className="">
                {editorAllowed &&
                    <button
                        className="btn btn-default float-right"
                        type="button"
                        disabled={disableAll}
                        onClick={openEditorPopup}>
                        {translate(editorTranslation)}
                    </button>
                }

                {isSendForApprovalEnabled &&
                    <button
                        className={"btn btn-primary me-2 mb-0"}
                        type="button"
                        disabled={disableAll}
                        onClick={() => {
                            action(DOCUMENT_ACTIONS.SEND_FOR_APPROVAL, selectedTaskKeys, {}, navigate);
                        }}>
                        {translate("documentOverview.actions.sendForApproval")}
                    </button>
                }

            </div>
        </div>
    )
};

const PreviewTooltip = ((props) => {

    const openWorkflowPanel = () => {
        eventBus.dispatch("openWorkflowPanel")
    }

    return (<div>
        <div className="mb-3">
            <strong className="h3 font-weight-bold">{props.translate('myTasks.reapprovalTooltip.title')}</strong>
        </div>
        {props.content.length === 1 ?
            <div>
                {props.content}
            </div> :
            <ul>
                {props.content.map((item, index) => {
                    return <li key={`preview-tooltip-item-${index}`}>{item}</li>
                })}
            </ul>}
        <div className="mb-3">
            {props.translate('myTasks.reapprovalTooltip.openPanel') + ' '}
            <a className="text-primary"
               onClick={openWorkflowPanel}>{props.translate('myTasks.reapprovalTooltip.workflowDetails')}</a>
            {' ' + props.translate('myTasks.reapprovalTooltip.forMoreInfo')}
        </div>
    </div>);
});

const TaskActionButtons = (props) => {
    if (props.actions.isEnabled(TASK_ACTIONS.REVIEW)) {
        return TaskReviewDetailsButtons(props);
    } else if (props.docType === DETAILS_VIEW_TYPE.DOCUMENT) {
        return DocumentDetailButtons(props);
    } else {
        return TaskDetailsButtons(props);
    }
};

export default TaskActionButtons;
