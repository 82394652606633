import React from "react";
import {
    Route,
    createBrowserRouter,
    createRoutesFromElements, RouterProvider,
} from "react-router-dom";
import {NotFound, WithoutStartup} from "../errorpage/ErrorPage.component.jsx";
import RouteTracker from "./VirtualPageTracker.component";
import Logout from "../usercontext/LogoutPage.component";
import MyTasks from '../mytasks/MyTasks.component';
import MyHistory from "../myhistory/MyHistory.component";
import TaskDetailMasterLayer from "../taskdetails-remake/TaskDetailsMasterLayer.component.jsx";
import TaskDetailMasterLayerByUID from "../taskdetails-remake/TaskDetailsMasterLayerByUID.component.tsx";
import HistoryTaskDetail from "../historydetail/HistoryTaskDetail.component";
import ProcessDetailsMasterLayer from "../processdetail/TaskDetailsMasterLayer.component.jsx";
import TaskOverview from '../taskoverview/TaskOverview.component.jsx';
import DocumentOverview from '../documentoverview/DocumentOverview.component.jsx';
import DocumentTaskDetail from "../documentdetail/DocumentTaskDetail.component";
import Settings from "../settings/Settings.component.jsx";
import ApproverNotifications from "../settings/ApproverNotifications.component";
import SubstituteSettings from "../settings/SubstituteSettings.component";
import OtherSettings from "../settings/OtherSettings.component.jsx";
import ConfigurationWrapper from "../configuration/ConfigurationWrapper.component.jsx";
import Configuration from "../configuration/Configuration.component";
import Workflows from "../workflows/Workflows.component";
import DueSettings from '../dueSettings/DueSettings.component';
import Substitutes from "../substitutes/Substitutes.component";
import Managers from "../managers/Managers.component";
import WorkflowDetails from "../workflowdetails/WorkflowDetails.component";
import Departments from "../departments/Departments.component";
import CostUnits from "../costunits/CostUnits.component";
import Roles from "../roles/Roles.component";
import Projects from '../projects/Projects.component';
import OrganisationChart from "../organisationChart/OrganisationChart.component";
import PreApproval from "../preapproval/PreApproval.component";
import WorkflowDetailsHistory from "../workflowdetails/WorkflowDetailsHistory.component";
import DecisionTables from "../decisionTables/decisionTables.component";
import DecisionTableDetails from "../decisionTableDetails/decisionTableDetails.component";
import {withRouteBlocker} from "components/router/withRouter";
import ProcessOverview from "components/processoverview/ProcessOverview.component.jsx";



export default function ApprovalRouter() {

    const TaskDetailsWithRouteBlocker = withRouteBlocker(TaskDetailMasterLayer);
    const TaskDetailsWithRouteBlockerByUID = withRouteBlocker(TaskDetailMasterLayerByUID);
    const ProcessDetailsWithRouteBlocker = withRouteBlocker(ProcessDetailsMasterLayer);
    const HistoryDetailsWithRouteBlocker = withRouteBlocker(HistoryTaskDetail);
    const DocumentDetailsWithRouteBlocker = withRouteBlocker(DocumentTaskDetail);
    const ApproverNotificationsWithRouteBlocker = withRouteBlocker(ApproverNotifications);
    const SubstituteSettingsWithRouteBlocker = withRouteBlocker(SubstituteSettings);
    const OtherSettingsWithRouteBlocker = withRouteBlocker(OtherSettings);

    const DueSettingsWithRouteBlocker = withRouteBlocker(DueSettings);
    const CostUnitsWithRouteBlocker = withRouteBlocker(CostUnits);
    const WorkflowDetailsWithRouteBlocker = withRouteBlocker(WorkflowDetails);



    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<RouteTracker/>}>
                <Route exact path="/" element={<MyTasks/>}/>
                <Route exact path="/task/:taskId" element={<TaskDetailsWithRouteBlocker/>}/>
                <Route exact path="/tasks/:uid" element={<TaskDetailsWithRouteBlockerByUID/>}/>
                <Route path="preapproval" element={<PreApproval />} />
                <Route path="history" element={<MyHistory/>}/>
                <Route path="taskhistory/:taskId" element={<HistoryDetailsWithRouteBlocker/>}/>
                <Route path="processoverview" element={<ProcessOverview/>}/>
                <Route path="processtask/:taskId" element={<ProcessDetailsWithRouteBlocker/>}/>
                <Route path="taskoverview" element={<TaskOverview/>}/>
                <Route path="documentoverview" element={<DocumentOverview/>}/>
                <Route path="document/:taskId" element={<DocumentDetailsWithRouteBlocker/>}/>

                <Route path="settings" element={<Settings/>}>
                    <Route index element={<ApproverNotificationsWithRouteBlocker/>}/>
                    <Route path="approverNotifications" element={<ApproverNotificationsWithRouteBlocker/>}/>
                    <Route path="substitute" element={<SubstituteSettingsWithRouteBlocker/>}/>
                    <Route path="otherSettings" element={<OtherSettingsWithRouteBlocker/>}/>
                </Route>

                <Route path="configuration">
                    <Route index element={<Substitutes/>}/>
                    <Route path="substitutes" element={<Substitutes/>}/>
                    <Route path="oldsettings" element={<Configuration/>}/>
                    <Route path="workflows" element={<Workflows/>}/>
                    <Route path="workflows/workflow/:workflowId" element={<WorkflowDetailsWithRouteBlocker/>}/>
                    <Route path="workflows/workflowHistory/:workflowId/:version" element={<WorkflowDetailsHistory/>}/>
                    <Route path="dueSettings" element={<DueSettingsWithRouteBlocker/>}/>
                    <Route path="roles" element={<Roles/>}/>
                    <Route path="managers" element={<Managers/>}/>
                    <Route path="departments" element={<Departments/>}/>
                    <Route path="projects" element={<Projects/>}/>
                    <Route path="costUnits" element={<CostUnitsWithRouteBlocker/>}/>
                    <Route path="organisationChart" element={<OrganisationChart/>}/>
                    <Route path="decisionTables" element={<DecisionTables/>}/>
                    <Route path="decisionTables/details/:name/:id" element={<DecisionTableDetails/>}/>
                </Route>


                <Route path="logout" element={<Logout/>}/>
                <Route path="/error" element={<WithoutStartup/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Route>
        )
    );

    return <RouterProvider  router={router} />
}

// export default class ApprovalRouter extends Component {
//
//     render() {
//         /*
//          for conditional redirection ('there are unsaved changes'), use:
//          https://github.com/ReactTraining/react-router/blob/v2.4.1/docs/guides/ConfirmingNavigation.md
//          */
//
//         // component shown upon openning app
//         const homeComponent = MyTasks;
//
//         return (
//             <Router history={history}>
//                 <Switch>
//                     <Route path="/error" component={WithoutStartup}/>
//                     <Route path="/"
//                            children={props => (
//                                <RouteTracker {...props}>
//                                    <Router>
//                                        <Switch>
//                                            <Route path="/preapproval" component={PreApproval}/>
//                                            <Route path="/history" component={MyHistory}/>
//                                            <Route path="/taskhistory/:taskId" {...props} component={HistoryTaskDetail}/>
//
//                                            <Route path="/task/:taskId" {...props} component={TaskDetailMasterLayer}/>
//
//                                            <Route path="/processoverview" component={SSEProcessOverviewWrapper}/>
//                                            <Route path="/processtask/:taskId" {...props} component={ProcessTaskDetail}/>
//
//                                            <Route path="/taskoverview" component={TaskOverview}/>
//
//                                            <Route path="/documentoverview" component={DocumentOverview}/>
//                                            <Route path="/document/:taskId" {...props} component={DocumentTaskDetail}/>
//
//                                            <Route path="/settings" children={props => (
//                                                <Settings {...props}>
//                                                    <Switch>
//                                                        <Route exact path="/settings" component={ApproverNotifications}/>
//                                                        <Route path="/settings/approverNotifications"
//                                                               component={ApproverNotifications}/>
//                                                        <Route path="/settings/substitute"
//                                                               component={SubstituteSettings}/>
//                                                        <Route path="/settings/otherSettings"
//                                                               component={OtherSettings}/>
//                                                        {/*<Route path="/settings/preferences"*/}
//                                                        {/*         component={Preferences}/>*/}
//                                                    </Switch>
//                                                </Settings>
//                                            )}/>
//
//                                            <Route path="/configuration" children={props => (
//                                                <ConfigurationRoot {...props}>
//                                                    <Switch>
//                                                        <Route exact path="/configuration" component={Substitutes}/>
//                                                        <Route exact path="/configuration/oldsettings"
//                                                               component={Configuration}/>
//                                                        <Route exact path="/configuration/workflows"
//                                                               component={Workflows}/>
//                                                        <Route exact
//                                                               path="/configuration/workflows/workflow/:workflowId" {...props}
//                                                               component={WorkflowDetails}/>
//                                                        <Route exact
//                                                               path="/configuration/workflows/workflowHistory/:workflowId/:version" {...props}
//                                                               component={WorkflowDetailsHistory}/>
//                                                        <Route path="/configuration/dueSettings"
//                                                               component={DueSettings}/>
//                                                        <Route path="/configuration/roles"
//                                                               component={Roles}/>
//                                                        <Route path="/configuration/substitutes"
//                                                               component={Substitutes}/>
//                                                        <Route path="/configuration/managers"
//                                                               component={Managers}/>
//                                                        <Route path="/configuration/departments"
//                                                               component={Departments}/>
//                                                        <Route path="/configuration/projects"
//                                                               component={Projects}/>
//                                                        <Route path="/configuration/costUnits"
//                                                               component={CostUnits}/>
//                                                        <Route path="/configuration/organisationChart"
//                                                               component={OrganisationChart}/>
//                                                        <Route exact path="/configuration/decisionTables"
//                                                               component={DecisionTables}/>
//                                                        <Route exact {...props}
//                                                               path="/configuration/decisionTables/details/:name/:id"
//                                                               component={DecisionTableDetails}/>
//
//
//                                                    </Switch>
//                                                </ConfigurationRoot>
//                                            )}/>
//
//                                            <Route exact path="/logout" component={Logout}/>
//                                            <Route exact path="index.html" component={homeComponent}/>
//
//                                            <Route exact path="/">
//                                                <MyTasks/>
//                                            </Route>
//                                            <Route component={NotFound}/>
//                                        </Switch>
//                                    </Router>
//                                </RouteTracker>
//                            )}
//                     />
//
//                 </Switch>
//             </Router>
//         );
//
//     };
// }
