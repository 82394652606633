import React from 'react';
import PropTypes from 'prop-types';
import Comment from '../input/Comment.component';
import {PopUp, VisibleToEverybody} from './PopUp.component';
import * as Api from 'utils/api/api';
import {TASK_ACTIONS} from 'utils/constants';
import ErrorDialog from './ErrorDialog.component';
import {replacePlaceholderInMessage, getTaskUserDescription} from 'utils/valueFormatter.function';
import translate from '../translations/translations.wrapper.jsx';
import { launchDarkly } from "utils/launchDarkly";

//this is to make sure the tabbing stays inside the dialog
const TAB_INDEX = 1000;

/**
 * popup which will be used in case user has selected 'reject'  single (in)
 * expects as props:
 * list of selected tasks
 * close popup callback
 * translate function
 */
export class Reject extends PopUp {
    constructor(props) {
        super(props);
        this.state = {
            comment: '',
            actionRunning: false,
            displayError: null,
            isHandled: false
        };
        this.ok = this.ok.bind(this);
        this.buildContent = this.buildContent.bind(this);
        this.focusFirst = this.focusFirst.bind(this);
        this.commentInputRef = React.createRef();
        this.getTaskByUID = launchDarkly.companyClient && launchDarkly.companyClient.variation("fe-get-task-by-uid", false);



        this._handle = this._handle.bind(this);
    }

    ok() {
        const self = this;

        if (this.state.comment) {
            this.setState({actionRunning: true});

            const key = this.props.tasks[0].key;
            const uid = this.props.tasks[0].uid;

            if (this.getTaskByUID) {
                Api.rejectTaskByUID(uid, this.state.comment).then(function (response) {
                    const withPlaceholder = replacePlaceholderInMessage(response.message, getTaskUserDescription(self.props.tasks[0]));
                    self.props.showNotification(withPlaceholder);
                    self.props.handledTaskCallback(key, self.props.navigate);
                    self.props.closeCallback();
                }).catch((error) => {
                    self.setState({displayError: error});
                });
            } else {
                Api.rejectTask(key, this.state.comment).then(function(response) {
                    const withPlaceholder = replacePlaceholderInMessage(response.message, getTaskUserDescription(self.props.tasks[0]));
                    self.props.showNotification(withPlaceholder);
                    self.props.handledTaskCallback(key, self.props.navigate);
                    self.props.closeCallback();
                }).catch((error) => {
                    self.setState({ displayError: error });
                });
            }
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.focusFirst();
    }

    focusFirst() {
        this.commentInputRef.current?.focus();
    }


    _handle() {
        this.setState({
            isHandled: true
        });
    }

    buildContent() {
        const okEnabled = this.state.comment && !this.state.actionRunning;
        return (
            <div>
                {this.state.displayError && <ErrorDialog
                    error={this.state.displayError}
                    closeCallback={() => this.setState({actionRunning: false, displayError: null})}
                    translate={this.props.translate}
                />}
                <div className="modal-body">
                    <label htmlFor="comment"><span
                        className="text-danger">* </span> {this.props.translate("popUp.reject.commentLabel")}
                    </label>
                    <VisibleToEverybody translate={this.props.translate}/>
                    <Comment ref={this.commentInputRef}
                             inputHint={this.props.translate("popUp.reject.commentHint")}
                             translate={this.props.translate}
                             tabIndex={TAB_INDEX}
                             id="comment"
                             shouldFocusFirst = {true}
                             propagateValue={value => this.setState({comment: value})}
                             value={this.state.comment}
                    />
                </div>
                <div className="modal-footer">
                    <div className="float-right">
                        <button className="btn btn-primary btn-margin-right ast_reject_ok_btn"
                                onClick={this.ok}
                                disabled={!okEnabled}
                                tabIndex={TAB_INDEX + 1}>
                            {this.props.translate("popUp.ok")}
                        </button>
                        <button className="btn btn-default"
                                onClick={this.props.closeCallback}
                                onBlur={this.focusFirst}
                                tabIndex={TAB_INDEX + 2}>
                            {this.props.translate("popUp.cancel")}
                        </button>
                    </div>
                </div>
            </div>

        );
    }

    getType() {
        return TASK_ACTIONS.REJECT;
    }

}

Reject.propTypes = {
    tasks: PropTypes.array, // selected tasks
    closeCallback: PropTypes.func, //callback for closing the popup
    handledTaskCallback: PropTypes.func,
    translate: PropTypes.func, // to translate texts
    showNotification: PropTypes.func, // to show transient notification
    navigate: PropTypes.func
};
const withTranslations = translate(Reject);
export default withTranslations;