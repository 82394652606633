import React from 'react';
import PropTypes from 'prop-types';
import {DropdownButton, Dropdown} from 'react-bootstrap';
import SearchGroupInput from '../input/SearchGroupInput.component';
import {MY_TASKS_DATES} from "utils/constants.js";
import {launchDarkly} from "utils/launchDarkly";
import _ from "lodash";


const SearchView = (props) => {

    const sortByInvoiceDateEnabled = launchDarkly.companyClient?.variation("enable-sort-by-invoice-date", false);

    let sort = function (value) {
        props.changeDisplayedDate(value);
    };

    const sortingDate = _.cloneDeep(MY_TASKS_DATES);

    if (!sortByInvoiceDateEnabled) {
        delete sortingDate["INVOICE_DATE"];
    }

    let dateToPick = Object.keys(sortingDate).map((key) => {
        return (<Dropdown.Item key={key} eventKey={MY_TASKS_DATES[key]}>
            {props.translate("myTasks.selectDate." + MY_TASKS_DATES[key])}
        </Dropdown.Item>);
    });

    let chooseDateToDisplay =
        (<div className="row date-dropdown col-md-5 text-right float-right">
            <div
                className="col-md-5 px-0 float-right label-text cropped-text">{props.translate("myTasks.selectDate.date")}</div>
            <div className="col-md-7 px-0 float-right">
                <DropdownButton id="SelectMyTasksDate" className="text-start my-tasks-date-select"
                                bsPrefix="btn btn-default"
                                title={props.translate("myTasks.selectDate." + props.displayedDate)}
                                onSelect={sort}>
                    {dateToPick}
                </DropdownButton>
            </div>

        </div>);

    return (
        <section className="container col-md-12 px-0">
            <div className="row margin-bottom mx-0">
                <aside className="search-group col-md-2 px-0" role="search">
                    <SearchGroupInput value={props.searchValue}
                                      onInputChange={(e) => {
                                          props.updateSearchCallback(e.target.value.toLowerCase())
                                      }}
                                      onClearInput={() => {
                                          props.updateSearchCallback()
                                      }}
                                      translate={props.translate}/>
                </aside>
                <aside className="col-md-10 px-0" aria-label={props.translate("myTasks.filterMyTasks")}>
                    {!props.oneCompanyOnlyUser &&
                        <div className="btn-toolbar btn-toolbar-primary float-right btn-margin-right" role="toolbar"
                             aria-label={props.translate("myTasks.filterMyTasks")}>
                            <div className="btn-group no-margin" role="group">
                                <button type="button"
                                        className={"btn btn-default " + (props.onlyCurrentCompany ? "" : "active")}
                                        onClick={() => props.updateCompanyFilterCallback(false)}
                                        data-cy="myTasks.allCompanies">
                                    {props.translate("myTasks.allCompanies")}
                                </button>
                                <button type="button"
                                        className={"btn btn-default " + (props.onlyCurrentCompany ? "active" : "")}
                                        onClick={() => props.updateCompanyFilterCallback(true)}
                                        data-cy="myTasks.currentCompany">
                                    {props.translate("myTasks.currentCompany")}
                                </button>
                            </div>
                        </div>
                    }
                    {chooseDateToDisplay}
                </aside>
            </div>
        </section>
    );

}

export default SearchView;

SearchView.propType = {
    updateSearchCallback: PropTypes.func,
    updateCompanyFilterCallback: PropTypes.func,
    translate: PropTypes.func,
    searchValue: PropTypes.string,
    onlyCurrentCompany: PropTypes.bool,
    oneCompanyOnlyUser: PropTypes.bool,
    changeDisplayedDate: PropTypes.func,
    displayedDate: PropTypes.string
};

