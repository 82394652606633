import {DOCUMENT_ACTIONS, TASK_ACTIONS} from 'utils/constants';
import EmailPopup from '../popup/EmailPopup.component';
import ApproveDirectly from '../popup/ApproveDirectlyPopup.component';
import RejectDirectly from '../popup/RejectDirectlyPopup.component';
import SendForApproval from '../popup/SendForApprovalPopup.component';
import {connect} from 'react-redux';
import {loadTasks} from '../mytasks/myTasks.action';
import {actionFinished} from '../mytasks/action/taskAction.action';
import {preventDocumentReload} from '../documentoverview/documentOverview.action';
import {approveMultipleTasks} from '../mytasks/action/approveMultipleTasks.action';
import {getDocumentOverviewTasks, getActionParameters, getUsersLoggedInData} from '../store/application.reducers';
import {showNotification} from '../notifications/notification.action';
import * as logger from 'utils/logger.function';
import {navigateBetweenDocuments} from '../mytasks/action/navigateBetweenTasks.action';
import {useNavigate} from "react-router-dom";
import * as reduxSelectors from "components/store/application.reducers.js";
import CancelDocuments from "components/popup/CancelDocumentsPopup.component.jsx";


/**
 * asynchronous part of the task action - showing the dialog, which will execute the action
 * synchronous part of action is inside redux action
 */
export const AsyncTaskActionExecutorDocument = (props) => {
    const navigate = useNavigate();

    const handleCallback = () => {
        props.preventDocumentReload(false);
        props.reloadTasks();
    }

    // nothing to show
    if (!props.actionParameters.type) {
        return null;
    }

    let taskList = props.tasks ? props.tasks.rows.map((task) => task) : [];

    const tasksForAction = taskList
        .filter((task) => { // find those matching props.taskIds
            return props.actionParameters.taskIds.some((taskKeyWanted) => {
                return taskKeyWanted === task.key;
            });
        });

    /**
     * sometimes, when user has dialog opened and navigates away for some time or changes context
     * the keys for task/history details expire, which then results in empty tasks for the popup
     * so detect this situation and rather hide the popup than check for invalid props inside each popup
     */
    if (tasksForAction.length === 0) {
        logger.error("AsyncTaskActionExecutor", "no task to be shown for these keys: " + props.actionParameters.taskIds);
        props.actionFinished();
        return null;
    }

    const popupProps = {
        closeCallback: props.actionFinished,
        handledTaskCallback: handleCallback,
        translate: props.translate,
        tasks: tasksForAction,
        showNotification: props.showNotification,
        refreshTaskList: props.loadTasks,
        approveMultipleTasks: props.approveMultipleTasks,
        taskDetails: props.taskDetails,
        skipComments: true,
        sendDocument: true,
        navigate: navigate,
        companyId: props.currentContextId
    };

    let popup = null;
    // according to type either show popup or execute action directly ..
    switch (props.actionParameters.type) {
        case DOCUMENT_ACTIONS.EMAIL:
            popup = <EmailPopup {...popupProps} loggedInData={props.loggedInData}/>;
            break;
        case DOCUMENT_ACTIONS.APPROVE_DIRECTLY:
            popup = <ApproveDirectly {...popupProps} />;
            break;
        case DOCUMENT_ACTIONS.REJECT_DIRECTLY:
            popup = <RejectDirectly {...popupProps} />;
            break;
        case DOCUMENT_ACTIONS.SEND_FOR_APPROVAL:
            popup = <SendForApproval {...popupProps} />;
            break;
        case TASK_ACTIONS.NEXT_TASK:
            const nextTaskId = props.navigateBetweenDocuments(tasksForAction[0].key, true);
            navigate(nextTaskId, {replace: true});
            props.actionFinished();
            break;
        case TASK_ACTIONS.PREVIOUS_TASK:
            const previousTaskId = props.navigateBetweenDocuments(tasksForAction[0].key, false);
            navigate(previousTaskId, {replace: true});
            props.actionFinished();
            break;
        case DOCUMENT_ACTIONS.CANCEL_DIRECTLY:
            popup = <CancelDocuments {...popupProps} />;
            break;
        default:
            console.error('unmapped action type: ' + props.actionParameters.type);
    }
    return popup;
}


const mapStateToProps = function (store) {
    return {
        tasks: getDocumentOverviewTasks(store),
        actionParameters: getActionParameters(store),
        loggedInData: getUsersLoggedInData(store),
        currentContextId: reduxSelectors.getUsersCurrentContextId(store)
    };
};

const connected = connect(mapStateToProps, {
    actionFinished,
    showNotification,
    loadTasks,
    approveMultipleTasks,
    navigateBetweenDocuments,
    preventDocumentReload
})(AsyncTaskActionExecutorDocument);


export default connected;