import React, {Component} from 'react';
import {TASK_ACTIONS, DETAILS_VIEW_TYPE} from 'utils/constants';
import {connect} from 'react-redux';
import {taskAction} from '../mytasks/action/taskAction.action';
import {navigateBetweenTasks} from '../mytasks/action/navigateBetweenTasks.action';
import {
    getTasksForRender,
    getSelectedDocument,
    getDetails,
    getOneHandleOnlyFlag,
    getTaskAttachments,
    getAttachmentsLoadingStatus,
    getTasksWithPayment
} from '../store/application.reducers';
import {TaskDetailActions} from '../mytasks/AvailableActions.class';
import {pageLoaded} from '../router/route.action';
import {
    removeSelectedDocument,
    getTaskDetails,
    handleAddTasksComments,
    getTaskCommentsData, handleAddTasksDraftComments, getTaskCommentsByUid,
} from "./taskDetails.action";
import {formatTaskDetailsTopBanner} from 'utils/valueFormatter.function';
import {handleNPKeyNavigation} from 'utils/keyPressHandler.function';
import _ from 'lodash';
import {loadTasks} from "../mytasks/myTasks.action";
import TaskDetails from "./TaskDetails.component";
import {PATH_TO_MY_TASKS} from "components/router/paths.js";
import { launchDarkly } from "utils/launchDarkly";


class TaskDetailMasterLayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attachments: null,
            task: null
        };
        this.getActionsForTask = this.getActionsForTask.bind(this);
        this.getTaskFromMyTasksList = this.getTaskFromMyTasksList.bind(this);
        this.requestData = this.requestData.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.getNavigation = this.getNavigation.bind(this);
        this.navigateBetweenTasks = this.navigateBetweenTasks.bind(this);
        this.useDraftComments = launchDarkly.userClient && launchDarkly.userClient.variation("fe-draft-comments", false);
    }

    /*
     * default view is the attachemnts page so we need to get the attachemnts first
     * */
    async componentDidMount() {
        // detail of current task is also in the store (use #getTaskDetailFromStore), but without for example the attachments, so we have to load it again
        //we should not need to get the tasks again, they should be in the store
        // await this.props.loadTasks();

        try {
            await this.requestData(this.props.match.params.taskId);
        } catch (error) {
            this.props.history("/");
            return;
        }

        this.props.removeSelectedDocument();
        document.addEventListener('keydown', this.handleKeyPress);
    }

    //this is needed to reload data at navigate between tasks with ctrl+alt+n
    componentDidUpdate(prevProps) {
        if (prevProps.match.params.taskId !== this.props.match.params.taskId) {
            this.setState({
                attachments: null
            });
            this.requestData(this.props.match.params.taskId);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    navigateBetweenTasks(taskId, isForward) {
        this.props.history(this.props.navigateBetweenTasks(taskId, isForward));
    }

    handleKeyPress(e) {
        handleNPKeyNavigation(e, this.props.match.params.taskId, this.navigateBetweenTasks);
    }

    getNavigation(task) {
        //fill in all the data found in the task
        let response = formatTaskDetailsTopBanner(task);
        let tasksAreFiltered = this.props.tasks.filteredKeys.length !== 0;

        response.companyName = task.companyName;
        //task number and number of selected tasks still need to be computed
        if (this.props.getOneHandleOnlyFlag) {
            let tasks = tasksAreFiltered ? this.props.tasks.filteredTasks : this.props.tasks.allTasks;
            response.currentTask = _.findIndex(tasks, (x => {
                return x.task.key === task.key;
            })) + 1;
            response.total = tasks.length;
        } else {
            response.currentTask = (this.props.tasks.selectedKeys.indexOf(task.key) + 1);
            response.total = this.props.tasks.selectedKeys.length;
        }

        return response;
    }

    requestData(taskId) {
        const task = this.getTaskFromMyTasksList();
        if (task) {
            this.setState({
                task: task
            })
        }

        return this.props.getTaskDetails(taskId);
    }

    getTaskFromMyTasksList() {
        const thisTaskArray = (this.props.tasks.allTasks || []).filter(task => {
            return task.task.key === this.props.match.params.taskId
        });
        // searched task is no longer in task list -> go to my tasks
        if (this.props.tasks.allTasks.length > 0 && thisTaskArray.length <= 0)
            this.props.history(PATH_TO_MY_TASKS);
        return thisTaskArray.length > 0 ? thisTaskArray[0].task : null;
    }

    getActionsForTask(navigation) {
        let activeActions = new TaskDetailActions();
        let task = this.state.task;
        if (!task) {
            return activeActions;
        }

        // map all from task
        activeActions.enableFromTaskDetail(task);

        if (task.isHandled) {
            activeActions.disableAll();
            activeActions.enable(TASK_ACTIONS.OPEN, TASK_ACTIONS.EMAIL);

        } else {
            // add open & postpone & email
            activeActions.enable(TASK_ACTIONS.OPEN, TASK_ACTIONS.POSTPONE, TASK_ACTIONS.EMAIL);

            // add attachment depending on task permission
            if (this.props.taskDetails.taskPermission?.allowUploadAttachment) {
                activeActions.enable(TASK_ACTIONS.ADD_ATTACHMENT);
            }

            if (task.preApprovedForPayment === false && this.props.tasksWithPayment.length > 0) {
                activeActions.enable(TASK_ACTIONS.APPROVE_BATCH);
            }
        }

        const isNotLastTask = navigation.currentTask !== navigation.total;
        const isNotFirstTask = navigation.currentTask !== 1;
        // Enable navigation actions
        if (isNotLastTask) activeActions.enable(TASK_ACTIONS.NEXT_TASK);
        if (isNotFirstTask) activeActions.enable(TASK_ACTIONS.PREVIOUS_TASK);
        return activeActions;
    }


    render() {
        const task = this.getTaskFromMyTasksList();

        if (!task) {
            return <div>
                <span className="appicon loading"/>
            </div>;
        }

        let navigation = this.getNavigation(task);
        const activeActions = this.getActionsForTask(navigation);
        let params = {
            taskId: this.props.match.params.taskId
        };

        return (
            <TaskDetails params={params}
                         task={task}
                         taskAction={this.props.taskAction}
                         navigation={navigation}
                         attachments={this.props.attachments}
                         taskDetails={this.props.taskDetails}
                         loadingStatus={this.props.loadingStatus}
                         route={this.props.route}
                         activeActions={activeActions}
                         navigateBetweenTasks={this.props.navigateBetweenTasks}
                         navigateBackPath={PATH_TO_MY_TASKS}
                         navigateBack={()=>{}}
                         type={DETAILS_VIEW_TYPE.TASK}
                         routerBlocker={this.props.blocker}
                         allowActionlessComments = {this.props.taskDetails.taskPermission?.allowExtraComments}
                         addCommentHandler={this.useDraftComments ? this.props.handleAddTasksDraftComments : this.props.handleAddTasksComments}
                         getCommentsHandler={this.useDraftComments ? this.props.getTaskCommentsByUid : this.props.getTaskCommentsData}/>
        );
    }
}

const mapStateToProps = function (store) {
    return {
        tasks: getTasksForRender(store),
        selectedDoc: getSelectedDocument(store),
        taskDetails: getDetails(store),
        getOneHandleOnlyFlag: getOneHandleOnlyFlag(store),
        attachments: getTaskAttachments(store),
        loadingStatus: getAttachmentsLoadingStatus(store),
        tasksWithPayment: getTasksWithPayment(store)
    };
};

const mapDispatchToProps = {
    taskAction,
    navigateBetweenTasks,
    pageLoaded,
    getTaskDetails,
    loadTasks,
    removeSelectedDocument,
    handleAddTasksComments,
    handleAddTasksDraftComments,
    getTaskCommentsData,
    getTaskCommentsByUid,
};

const connected = connect(mapStateToProps, mapDispatchToProps)(TaskDetailMasterLayer);

export default connected;