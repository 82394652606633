import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as reduxSelectors from "components/store/application.reducers";
import { DraftAndCommentsProps } from "types/comments";
import translate from "components/translations/translations.wrapper";
import CommentsPanelComponent from "components/taskdetails-remake/panels/draftcomments/CommentsPanel.component";
import { COMMENT_SAVING_STATUS } from "utils/constants";

import { cancelRequests } from "utils/api/api";
import {
    getDraftComment,
    draftCommentLoaded,
    draftCommentSavingStatusChange,
} from "components/taskdetails-remake/taskDetails.action";
import { saveNewDraftComment } from "components/taskdetails-remake/panels/draftcomments/draftComments.action";


const DraftAndComments = (props: DraftAndCommentsProps) => {

    const [offline, setOffline] = useState(false);
    const [saveTimeout, setSaveTimeout] = useState(undefined);
    const [statusTimeout, setStatusTimeout] = useState(undefined)

    const savingStatus = useSelector(reduxSelectors.getTaskDraftCommentsSavingStatus);
    const savedDraftComment = useSelector(reduxSelectors.getTaskDraftComment);
    const requests = [];
    const dispatch = useDispatch();

    useEffect(() => {
        requestData(props.taskId);
        return () => {
            cancelRequests(requests);
        };
    }, [props.taskId]);


    useEffect(() => {
        if(savingStatus === COMMENT_SAVING_STATUS.DONE) {
            if(statusTimeout !== undefined) {
                clearTimeout(statusTimeout);
            }
            setStatusTimeout(setTimeout(() => {
                dispatch(draftCommentSavingStatusChange(COMMENT_SAVING_STATUS.IDLE))
            }, 5000));
        }
    }, [savingStatus]);

    const requestData = (taskId: string) => {
        if (props.getCommentsHandler)
            props.getCommentsHandler(props.uid, taskId);

        dispatch(getDraftComment(props.documentUid));
    };

    const navigatorOnlineCheck = (saveAction: any, comment?: string) => {
        if (!navigator.onLine) {
            setOffline(true);
            dispatch(draftCommentSavingStatusChange(COMMENT_SAVING_STATUS.SAVING));
            setSaveTimeout(setTimeout(saveAction, 1000, comment));
            return;
        } else {
            setOffline(false);
        }
    }

    const saveDraftComment = async (comment) => {
        navigatorOnlineCheck(saveDraftComment, comment);

        dispatch(draftCommentSavingStatusChange(COMMENT_SAVING_STATUS.SAVING));

        for (const request of requests) {
            await request;
        }
        requests.push(saveNewDraftComment(dispatch, comment, savedDraftComment, props.documentUid));
    };

    const saveComment = () => {
        navigatorOnlineCheck(saveComment);

        dispatch(draftCommentSavingStatusChange(COMMENT_SAVING_STATUS.SAVING));
        if (props.addCommentHandler) {
            props.addCommentHandler(props.taskId, savedDraftComment, props.documentUid, props.uid);
        }
    };

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let comment = event.target.value;

        if (comment !== savedDraftComment) {
            if(savingStatus !== COMMENT_SAVING_STATUS.START) {
                dispatch(draftCommentSavingStatusChange(COMMENT_SAVING_STATUS.START));
            }
            dispatch(draftCommentLoaded({text: comment}))
            if (saveTimeout !== undefined) {
                clearTimeout(saveTimeout);
            }

            setSaveTimeout(setTimeout(saveDraftComment, 1000, comment));
        }
    };

    return <CommentsPanelComponent
        readOnly={props.readOnly}
        initialCommentsCount={props.initialCommentsCount}
        comment={savedDraftComment}
        offline={offline}
        onChange={onChange}
        onSaveComment={saveComment}
        allowExtraComments = {props.allowExtraComments}
    />;
};


const withTranslations = translate(DraftAndComments);

export default withTranslations;

