import React from 'react';
import PropTypes from 'prop-types';
import Comment from '../input/Comment.component';
import {PopUp, VisibleToEverybody} from './PopUp.component';
import {DOCUMENT_ACTIONS} from 'utils/constants';
import translate from '../translations/translations.wrapper.jsx';
import * as Api from 'utils/api/api.js';
import WorkflowMapView from '../workflowdetails/workflowmap/WorkflowMapView.component';
import {handleError} from 'utils/errorHandle.function';
import {PATH_TO_DOCUMENT_OVERVIEW} from "components/router/paths.js";
import WorkflowDetailsWizard
    from "components/taskdetails-remake/panels/workflowdetails/WorkflowDetailsWizard.component.jsx";
import {launchDarkly} from "utils/launchDarkly";
import {SimulatorErrorsLog} from "components/workflowdetails/simulator/Simulator.component.jsx";

//this is to make sure the tabbing stays inside the dialog
const TAB_INDEX = 1000;

/**
 * popup which will be used in case user has selected 'approve with comment'
 * expects as props:
 * list of selected tasks
 * close popup callback
 * translate function
 */
export class SendForApproval extends PopUp {

    static propTypes = {
        tasks: PropTypes.array, // selected tasks
        closeCallback: PropTypes.func, //callback for closing the popup
        handledTaskCallback: PropTypes.func, //callback to mark task as handled
        translate: PropTypes.func, // to translate texts
        showNotification: PropTypes.func, // to show transient notification
        approveMultipleTasks: PropTypes.func,
        navigate: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.ok = this.ok.bind(this);
        this.buildContent = this.buildContent.bind(this);
        this.useNewWorkflowGraph = launchDarkly.companyClient && launchDarkly.companyClient.variation("use-new-workflow-graph", false);

        this.state = {
            comment: '',
            actionRunning: this.props.tasks.length === 1, // the proofing starts running at componentdidmount
            workflow: "",
            workflowMap: "",
            warning: undefined,
            loadingSimulator: null
        }
    }

    ok() {
        const self = this;

        this.setState({actionRunning: true});

        const taskIds = this.props.tasks.map((task) => {
            return task.key;
        });

        let commentToSend = this.state.comment ? this.state.comment : '';

        Api.sendDocumentForApproval(commentToSend, taskIds, this.props.companyId).then(response => {
            self.props.showNotification(response.message);
            self.props.handledTaskCallback(taskIds, self.props.navigate);
            self.props.closeCallback();
            self.props.navigate(PATH_TO_DOCUMENT_OVERVIEW);
        }, error => {
            handleError(error);
        });

    }

    componentDidMount() {
        super.componentDidMount();
        this.okButton?.focus();

        //we can run the simulator for just one task
        if (this.props.tasks.length === 1) {
            this.setState({
                loadingSimulator: true
            });

            if (!this.useNewWorkflowGraph) {
                Api.SimulateWorkflow(this.props.tasks[0].key).then(response => {
                        this.setState({
                            workflow: response.imageBase64,
                            workflowMap: response.coordinates,
                            loadingSimulator: false,
                            actionRunning: false
                        });
                    },
                    error => {
                        this.setState({
                            warning: error.errorMessages,
                            loadingSimulator: false
                        });

                        this.cancelButton?.focus();
                    });

            } else {
                Api._simulateWorkflowGraph(this.props.tasks[0].key, this.props.companyId).then(response => {
                        this.setState({
                            workflowGraph: response,
                            loadingSimulator: false,
                            actionRunning: false
                        });
                    },
                    error => {
                        this.setState({
                            warning: error.errorMessages
                        });

                        this.cancelButton?.focus();
                    });
            }
        }
    }

    buildContent() {

        const okEnabled = !this.state.actionRunning;

        return (
            <div>
                <div className="modal-body mb-4">
                    <div>
                        <label htmlFor="comment">{this.props.translate("popUp.approve.commentLabel")}</label>
                    </div>
                    <VisibleToEverybody translate={this.props.translate}/>
                    <Comment
                        ref={(element) => {
                            this.commentInput = element;
                        }}
                        inputHint={this.props.translate("popUp.approve.commentHint")}
                        translate={this.props.translate}
                        tabIndex={TAB_INDEX}
                        id="comment"
                        value={this.state.comment}
                        propagateValue={value => this.setState({comment: value})}
                    />
                    {this.props.tasks.length === 1 &&
                        <div>
                            {this.useNewWorkflowGraph && this.state.workflowGraph ?

                                <div>
                                    <WorkflowDetailsWizard data={this.state.workflowGraph.steps}
                                                           hideSkippedSteps={true}
                                    />
                                    {this.state.workflowGraph.success === false && this.state.workflowGraph?.log &&
                                        <SimulatorErrorsLog nodes={this.state.workflowGraph.log.children}
                                                            bsClass={"overflow-auto send-for-approval-log mb-2"}
                                                            title={this.props.translate("workflowDetails.simulator.errorLog")}/>

                                    }

                                    <WorkflowFeedbackMessage isWarning={this.state.workflowGraph.success === false}
                                                             isSuccess={this.state.workflowGraph.success}
                                                             translate={this.props.translate}/>
                                </div> :

                                <div>
                                    <div className="simulator col-md-12 mt-2 px-0 panel panel-default">
                                        {this.state.loadingSimulator &&
                                            <LoadingSimulator translate={this.props.translate}/>
                                        }
                                        {this.state.warning ?
                                            <div className="warning"
                                                 style={{whiteSpace: "pre-wrap"}}>{this.state.warning}</div>
                                            :
                                            this.state.workflow &&
                                            <div className="col-md-12 px-0">
                                                <WorkflowMapView url={this.state.workflow}
                                                                 coordinates={this.state.workflowMap}/>
                                            </div>
                                        }
                                    </div>
                                    <WorkflowFeedbackMessage isWarning={this.state.warning}
                                                             isSuccess={this.state.workflow}
                                                             translate={this.props.translate}/>

                                </div>}

                        </div>
                    }
                </div>

                <div className="modal-footer">
                    <div className="float-right">
                        <button className="btn btn-primary btn-margin-right"
                                onClick={this.ok}
                                disabled={!okEnabled || this.state.warning}
                                ref={(element) => {
                                    this.okButton = element;
                                }}
                                tabIndex={TAB_INDEX + 1}>
                            {this.props.translate("popUp.ok")}
                        </button>
                        <button className="btn btn-default"
                                onBlur={() => {
                                    this.commentInput.focus();
                                }}
                                ref={(element = null) => {
                                    this.cancelButton = element;
                                }}
                                onClick={this.props.closeCallback}
                                tabIndex={TAB_INDEX + 2}>
                            {this.props.translate("popUp.cancel")}
                        </button>
                    </div>
                </div>
            </div>

        );
    }

    getType() {
        return DOCUMENT_ACTIONS.SEND_FOR_APPROVAL;
    }

    getTitle() {
        if (this.props.tasks.length === 1)
            return this.props.translate("popUp.sendforapproval.titleSingle", this.props.tasks[0].description);
        return this.props.translate("popUp.sendforapproval.titleMultiple", this.props.tasks.length);
    }


};

const withTranslations = translate(SendForApproval);
export default withTranslations;

const WorkflowFeedbackMessage = ({isSuccess, isWarning, translate}) => {
    if (isSuccess) {
        return (<div className="col-md-12 m-0 alert alert-sm alert-success" role="alert">
            <span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-success"/>
            <span>{translate("popUp.sendforapproval.successText")}</span>
        </div>)
    }

    if (isWarning) {
        return (<div className="col-md-12 m-0 alert alert-sm alert-danger" role="alert">
            <span className="vismaicon vismaicon vismaicon-filled vismaicon-error"/>
            <span>{translate("popUp.sendforapproval.errorText")}</span>
        </div>)
    }
    return null;

};


const LoadingSimulator = ({translate}) => {
    return (
        <div className="horizontal-loading horizontal-loading-sm p-0 mt-5">
            <div className="loading-bar" role="progress"></div>
            <span className="loading-bar-label">{translate("popUp.sendforapproval.runningSimulation")}</span>
        </div>
    )
};