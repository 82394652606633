import React from 'react';
import PropTypes from 'prop-types';
import Comment from '../input/Comment.component';
import {PopUp} from './PopUp.component';
import * as Api from 'utils/api/api';
import {Col, Container, Row} from 'react-bootstrap';
import {TASK_ACTIONS} from 'utils/constants';
import ErrorDialog from './ErrorDialog.component';
import {connect} from 'react-redux';
import {getTaskDetails, updateTaskDetailsAfterAction} from '../taskdetails-remake/taskDetails.action';
import translate from '../translations/translations.wrapper.jsx';
import * as reduxSelectors from '../store/application.reducers';
import {mapErrorMessage} from 'utils/errorHandle.function';

//this is to make sure the tabbing stays inside the dialog
const TAB_INDEX = 1000;

/**
 * popup which will be used in case user has selected 'add attachment'
 * expects as props:
 * list of selected tasks
 * close popup callback
 * translate function
 */
export class AddAttachment extends PopUp {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            title: '',
            comment: '',
            actionRunning: false,
            displayError: null,
            fileToBigMessage: ""
        }
        this.ok = this.ok.bind(this);
        this.buildContent = this.buildContent.bind(this);
        this.fileAdded = this.fileAdded.bind(this);
        this.focusFirst = this.focusFirst.bind(this);
    }

    fileAdded(event) {
        //there will always be only one file
        this.setState({
            file: event.target.files[0],
            fileToBigMessage: undefined
        });
    }

    ok() {
        this.setState({actionRunning: true});
        const self = this;
        this.props.tasks.forEach(({key}) => {
            Api.addAttachment(key, encodeURIComponent(this.state.title), encodeURIComponent(this.state.comment), this.state.file).then(function (response) {
                    let documentName = JSON.parse(response)?.text || '';
                    self.props.updateTaskDetailsAfterAction(key, TASK_ACTIONS.ADD_ATTACHMENT);
                    self.props.showNotification(documentName + " " + self.props.translate("popUp.addAttachment.hasBeenAdded"));
                    self.props.closeCallback();
                },
                function (error) {
                    self.setState({
                        fileToBigMessage: mapErrorMessage(error),
                        actionRunning: false
                    });
                }
            );
        });

    }

    focusFirst() {
        this.refs.titleInput.focus();
    }

    componentDidMount() {
        super.componentDidMount();
        this.focusFirst();
    }

    buildContent() {
        const okEnabled = !this.state.actionRunning && this.state.comment && this.state.title && this.state.file && !this.state.fileToBigMessage;

        let fileName = "";
        let isPDFAllowed = this.props.taskDetail.taskPermission?.allowPdfUpload === true;
        let isHTMLAllowed = this.props.taskDetail.taskPermission?.allowHtmlUpload === true;
        let isTIFFAllowed = this.props.taskDetail.taskPermission?.allowTiffUpload === true;
        let fileLabelClass = this.state.fileToBigMessage ? "text-danger me-2" : "text-disabled me-2";
        let acceptedFileExtensions = isPDFAllowed ? ".png, .pdf, .jpg, .jpeg" : ".png, .jpg, .jpeg";
        let acceptedFilesHint = isPDFAllowed ? "popUp.addAttachment.browseHint" : "popUp.addAttachment.browseHintPNG";
        let acceptedFilesHintAdditionalTypes = "";
        if (isHTMLAllowed) {
            acceptedFileExtensions += ", .html";
            acceptedFilesHintAdditionalTypes += ", HTML";
        }
        if (isTIFFAllowed) {
            acceptedFileExtensions += ", .tif, .tiff";
            acceptedFilesHintAdditionalTypes += ", TIFF";
        }

        if (this.state.file != null)
            fileName = this.state.file.name;
        return (
            <div>
                {this.state.displayError && <ErrorDialog
                    error={this.state.displayError}
                    closeCallback={() => this.setState({actionRunning: false, displayError: null})}
                    translate={this.props.translate}
                />}
                <div className="modal-body ast_add_attachments" aria-modal="true">
                    <Container bsClass="px-0">
                        <Row className="mb-2">
                            <Col xs={3} md={3}>
                                <label htmlFor="title" className="align-middle lh-32">
                                    <span className="text-danger">* </span>
                                    {this.props.translate("popUp.addAttachment.fileTitleLabel")}
                                </label>
                            </Col>
                            <Col xs={9} md={9}>
                                <input className="col-md-9 add-attachment-title" type="text" id="title"
                                       tabIndex={TAB_INDEX}
                                       ref="titleInput"
                                       value={this.state.title}
                                       onChange={(e) => this.setState({title: e.target.value})}
                                />
                            </Col>

                        </Row>
                        <Row className="mb-2">
                            <Col xs={12} md={12}>
                                <label htmlFor="comment" className="col-md-3 px-0 align-middle lh-32">
                                    <span className="text-danger">* </span>
                                    {this.props.translate("popUp.addAttachment.commentLabel")}
                                </label>
                                <Comment
                                    inputHint={this.props.translate("popUp.approve.commentHint")}
                                    inputLimit={256}
                                    translate={this.props.translate}
                                    tabIndex={TAB_INDEX + 1}
                                    propagateValue={(value) => this.setState({comment: value})}
                                    id="comment"
                                    hideInitialValue = {true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} className="px-0">
                                <label htmlFor="fileInput" className="col-md-2 align-middle lh-32">
                                    <span className="text-danger">* </span>
                                    {this.props.translate("popUp.addAttachment.fileLabel")}
                                </label>
                                <div className="col-md-10 float-right">
                                    <span className={fileLabelClass}>{fileName}</span>
                                    <label htmlFor="fileInput" tabIndex={TAB_INDEX + 2} className="btn btn-default">
                                        <input id="fileInput" type="file" style={{display: "none"}}
                                               accept={acceptedFileExtensions}
                                               onChange={this.fileAdded}/>
                                        {this.props.translate("popUp.addAttachment.browse")}
                                    </label>
                                </div>
                            </Col>
                            <Col xs={12} md={12}>
                                <span className="text-disabled col-md-12 px-0">
                                  {this.props.translate(acceptedFilesHint)}{acceptedFilesHintAdditionalTypes}
                                 </span>
                                <span className="text-danger col-md-12 px-0">
                                    {this.state.fileToBigMessage}
                                </span>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="modal-footer">
                    <div className="float-right">
                        <button
                            className="btn btn-primary btn-margin-right"
                            onClick={this.ok}
                            disabled={!okEnabled}
                            tabIndex={TAB_INDEX + 3}> {this.props.translate("popUp.ok")
                        }</button>
                        <button className="btn btn-default"
                                onBlur={this.focusFirst}
                                onClick={this.props.closeCallback}
                                tabIndex={TAB_INDEX + 4}>
                            {this.props.translate("popUp.cancel")}
                        </button>
                    </div>
                </div>
            </div>
        )
            ;
    }

    getType() {
        return TASK_ACTIONS.ADD_ATTACHMENT;
    }

}

AddAttachment.propTypes = {
    tasks: PropTypes.array, // selected tasks
    closeCallback: PropTypes.func, //callback for closing the popup
    translate: PropTypes.func, // to translate texts
}

const withTranslations = translate(AddAttachment);
const mapStateToProps = function (store) {
    return {
        taskDetail: reduxSelectors.getTaskDetails(store)
    }
};

const connected = connect(mapStateToProps, {
    getTaskDetails,
    updateTaskDetailsAfterAction
})(withTranslations);

export default connected;
